import React, { useEffect, useState } from "react";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { AiOutlineArrowRight } from "react-icons/ai";
// import { useAgentDetailsStyle } from "@/static/stylesheets/molecules/agentDetailsStyle";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Avatar, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { GrFormCheckmark } from "react-icons/gr";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import { AppService } from "@/service/AllApiData.service";
import filterbtn from "@/static/images/button.png";
import { formatIndianRupee } from ".";
import DatePicker from "react-multi-date-picker";
import { getDateRange, getFormatedDateRange } from "@/helper/date";
import { useAgentDetailsStyle } from "@/static/stylesheets/molecules/agentDetailsStyle";
import moment from "moment";
import { Close, CropSharp, Info, Pending } from "@mui/icons-material";
import { FaCross } from "react-icons/fa";


interface AgentReportType {
    data?: any;
    id?: any;
}
const Agentreport: React.FC<AgentReportType> = ({ id, data }) => {
    const classes = useAgentDetailsStyle();
    const [activeButton, setActiveButton] = React.useState('wholesaler');
    const [selectedYear, setSelectedYear] = React.useState<number>(new Date().getFullYear());
    const [selectedYear1, setSelectedYear1] = React.useState<number>(new Date().getFullYear());
    const [selectedYear2, setSelectedYear2] = React.useState<number>(new Date().getFullYear());

    const [AllBazaarData, setAllBazaarData] = React.useState<any>([]);
    const [bazaarList, setBazaarList] = React.useState([]);
    const [bazaarList1, setBazaarList1] = React.useState([]);
    const [bazaarList2, setBazaarList2] = React.useState([]);

    const [wholeSellerData, setWholesellerData] = React.useState<any>();
    const [earningData, setEarningData] = React.useState<any>();
    const [cities, setCities] = React.useState<any[]>();
    const [city, setCity] = React.useState<any>();
    const [districts, setDistricts] = React.useState<any[]>();
    const [district, setDistrict] = React.useState<any>();
    const [states, setStates] = React.useState<any[]>();
    const [state, setState] = React.useState<any>();
    const [planExpiry, setPlanExpiry] = React.useState<any[]>([]);
    const [monthWiseWholeseller, setMonthWiseWholeseller] = React.useState<any>([]);
    const [monthWiseEarning, setMonthWiseEarning] = React.useState<any>([]);
    const [reportFilter, setReportFilter] = React.useState<any>("today");
    const [monthReportFilter, setMonthReportFilter] = React.useState<any>("today");
    const [monthEarningFilter, setMonthEarningFilter] = React.useState<any>("today");
    const [dateRange, setDateRange] = useState<any>();
    const [dateRange1, setDateRange1] = useState<any>();
    const [dateRange2, setDateRange2] = useState<any>();
    const [cityWiseWholeSellers, setCityWiseWholeSellers] = useState<any[]>();
    const [filteredCityWiseWholeSellers, setFilteredCityWiseWholeSellers] = useState<any[]>()
    const [transactionHistory, setTransactionHistory] = useState<any>([]);


    const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>, setState: any) => {
        setState(event.target.value as number);
    };

    useEffect(() => {
        let params = {}
        if (district) {
            params = {
                district
            }
        }
        getCityWiseEarnings(params)
    }, [district])

    const getCityWiseEarnings = async (params: any) => {
        try {
            const response = await AppService.cityWiseEarnings(id, params)
            setCityWiseWholeSellers(response.data.commission_by_city)
        } catch (err: any) {
            console.log("ERROR ====> ", err.message)
        }
    }

    const currentYear = new Date().getFullYear();

    const years = Array.from({ length: currentYear - 2000 + 1 }, (_, index) => 2000 + index);

    const WholesalersMonth = [{ id: 1, month: "January", totalWholesalers: 120 }, { id: 2, month: "February", totalWholesalers: 50 }, { id: 3, month: "March", totalWholesalers: 150 }, { id: 4, month: "April", totalWholesalers: 150 }, { id: 5, month: "May", totalWholesalers: 150 }, { id: 6, month: "June", totalWholesalers: 130 }, { id: 7, month: "July", totalWholesalers: 150 }, { id: 8, month: "August", totalWholesalers: 70 }, { id: 9, month: "September", totalWholesalers: 150 }, { id: 10, month: "October", totalWholesalers: 150 }, { id: 11, month: "November", totalWholesalers: 150 }, { id: 12, month: "December", totalWholesalers: 159 }]

    const earningsMonth = [{ id: 1, quarter: "Quarter 1", totalEarning: 120 }, { id: 2, quarter: "Quarter 2", totalEarning: 50 }, { id: 3, quarter: "Quarter 3", totalEarning: 150 }, { id: 4, quarter: "Quarter 4", totalEarning: 150 }]

    useEffect(() => {
        getAllBazaar()
        getAllBazaarData()
        // getAgentReport()
        // getAllData();
        getCities();
        getDistricts();
        getPlansExpiry();
    }, [])

    const getTransactionHistory = async (params: any) => {
        try {
            const response = await AppService.getAgentsTransactionHistory(params);
            setTransactionHistory(response.data.results)
        } catch (err: any) {
            console.log("ERROR ====> ", err)
        }
    }

    useEffect(() => {
        let params = {};
        if (reportFilter) {
            const date = reportFilter === "range" ? getFormatedDateRange(dateRange) : getDateRange(reportFilter) as any
            params = { ...params, start_date: date?.start_date, end_date: date?.end_date }
        }
        if (selectedYear) {
            params = { ...params, year: selectedYear }
        }
        const bazzars = bazaarList.filter((item: any) => item.status === true).map((item: any) => item.value)
        if (bazzars?.length) {
            params = { ...params, bazaar: bazzars.join(",") }
        }
        getAllData(params)
    }, [reportFilter])

    useEffect(() => {
        let params = {};
        if (monthReportFilter) {
            const date = monthReportFilter === "range" ? getFormatedDateRange(dateRange1) : getDateRange(monthReportFilter) as any
            params = { ...params, start_date: date?.start_date, end_date: date?.end_date }
        }
        if (selectedYear1) {
            params = { ...params, year: selectedYear1 }
        }
        const bazzars = bazaarList1.filter((item: any) => item.status === true).map((item: any) => item.value)
        if (bazzars?.length) {
            params = { ...params, bazaar: bazzars.join(",") }
        }
        getMonthWiseWholeSeller(params)
    }, [monthReportFilter])

    useEffect(() => {
        let params = {};
        if (monthEarningFilter) {
            const date = monthEarningFilter === "range" ? getFormatedDateRange(dateRange2) : getDateRange(monthEarningFilter) as any
            params = { ...params, start_date: date?.start_date, end_date: date?.end_date }
        }
        if (selectedYear2) {
            params = { ...params, year: selectedYear2 }
        }
        const bazzars = bazaarList2.filter((item: any) => item.status === true).map((item: any) => item.value)
        if (bazzars?.length) {
            params = { ...params, bazaar: bazzars.join(",") }
        }
        getMonthWiseEarning(params)
    }, [monthEarningFilter])


    const handleReportUpdate = () => {
        let params = {};
        if (reportFilter) {
            const date = reportFilter === "range" ? getFormatedDateRange(dateRange) : getDateRange(reportFilter) as any
            params = { ...params, start_date: date?.start_date, end_date: date?.end_date }
        }
        if (selectedYear) {
            params = { ...params, year: selectedYear }
        }
        const bazzars = bazaarList.filter((item: any) => item.status === true).map((item: any) => item.value)
        if (bazzars?.length) {
            params = { ...params, bazaar: bazzars.join(",") }
        }
        getAllData(params);
    }

    const handleWholeSellerUpdate = () => {
        let params = {};
        if (monthReportFilter) {
            const date = monthReportFilter === "range" ? getFormatedDateRange(dateRange1) : getDateRange(monthReportFilter) as any
            params = { ...params, start_date: date?.start_date, end_date: date?.end_date }
        }
        if (selectedYear1) {
            params = { ...params, year: selectedYear1 }
        }
        const bazzars = bazaarList1.filter((item: any) => item.status === true).map((item: any) => item.value)
        if (bazzars?.length) {
            params = { ...params, bazaar: bazzars.join(",") }
        }
        getMonthWiseWholeSeller(params);
    }

    const handleEarningUpdate = () => {
        let params = {};
        if (monthEarningFilter) {
            const date = monthEarningFilter === "range" ? getFormatedDateRange(dateRange2) : getDateRange(monthEarningFilter) as any
            params = { ...params, start_date: date?.start_date, end_date: date?.end_date }
        }
        if (selectedYear2) {
            params = { ...params, year: selectedYear2 }
        }
        const bazzars = bazaarList2.filter((item: any) => item.status === true).map((item: any) => item.value)
        if (bazzars?.length) {
            params = { ...params, bazaar: bazzars.join(",") }
        }
        getMonthWiseEarning(params);
    }


    const getAllData = async (params: any) => {
        // let param = {}
        // if (date) {
        //     param = {
        //         year: new Date(date).getFullYear(),
        //         month: new Date(date).getMonth(),
        //         week: new Date(date).getDate(),
        //     }
        // }
        await getWholesellerCount(params);
        await getEarning(params);
        await getTransactionHistory(params)
    }

    const getAgentReport = async () => {
        try {
            const resposne = await AppService.agentReport({});
            console.log("Agent Rerpot....", resposne.data)
        } catch (err: any) {
            console.log("AGENt REPORT ERROR ====> ", err.message)
        }
    }

    const getWholesellerCount = async (params: any) => {
        try {
            const resposne = await AppService.agentWholesellerCount(params, id);
            console.log("Agent wholesellercount....", resposne.data)
            setWholesellerData(resposne.data);
        } catch (err: any) {
            console.log("AGENt COUNT ERROR ====> ", err.message)
        }
    }

    const getEarning = async (params: any) => {
        try {
            const resposne = await AppService.totaleEarningCount(id, params);
            console.log("GET EARNING ===>", resposne.data)
            setEarningData(resposne.data);
        } catch (err: any) {
            console.log("AGENt EARNING ERROR ====> ", err.message)
        }
    }

    const getCities = async (params: any = {}) => {
        try {
            const resposne = await AppService.getAllCity();
            console.log("GET CITIES ===> ", resposne.data)
            setCities(resposne.data.results.map((item: any) => ({
                label: item.city,
                value: item.id
            })));

            // setCity(resposne.data.results?.[0]?.id)
            getWholesellersByCity({ city: resposne.data.results?.[0]?.id })
        } catch (err: any) {
            console.log("GET CITIES ERROR ====> ", err.message)
        }
    }

    const getDistricts = async (params: any = {}) => {
        try {
            const resposne = await AppService.getAllDistric();
            console.log("GET DISTRICT ===> ", resposne.data)
            setDistricts(resposne.data.results.map((item: any) => ({
                label: item.district,
                value: item.id
            })));
        } catch (err: any) {
            console.log("GET DISTRICT ERROR ====> ", err.message)
        }
    }

    const getWholesellersByCity = async (param: any) => {
        try {
            const response = await AppService.wholeSellerList(id, param);
            console.log("logger123...wholesellers", response.data);
        } catch (err: any) {
            console.log("GET WHOLESELLERS ERROR ====> ", err.message)

        }
    }

    const getPlansExpiry = async (params: any = {}) => {
        try {
            const resposne = await AppService.planExpiry(id, params);
            console.log("GET PLANS ===> ", resposne.data)
            setPlanExpiry(resposne.data.wholesellers);
        } catch (err: any) {
            console.log("GET PLANS ERROR ====> ", err.message)
        }
    }

    // useEffect(() => {
    //     getMonthWiseReport();
    // }, [activeButton])

    // const getMonthWiseReport = async () => {
    //     getMonthWiseEarning();
    //     getMonthWiseWholeSeller();
    // }

    const getMonthWiseWholeSeller = async (params: any = {}) => {
        try {
            const resposne = await AppService.monthWiseWholeSeller(id, params);
            console.log("GET WHOLESELLER ===> ", resposne.data)
            setMonthWiseWholeseller(resposne.data);
        } catch (err: any) {
            console.log("GET WHOLESELLER ERROR ====> ", err.message)
        }
    }

    const getMonthWiseEarning = async (params: any = {}) => {
        try {
            const resposne = await AppService.monthWiseEarning(id, params);
            console.log("GET EARNING ===> ", resposne.data)
            setMonthWiseEarning(resposne.data);
        } catch (err: any) {
            console.log("GET EARNING ERROR ====> ", err.message)
        }
    }

    const getAllBazaarData = async () => {
        const responseJson = await AppService.getAllBazaar()
        setAllBazaarData(responseJson.data.results);
    };
    const getAllBazaar = async () => {
        const responseJson = await AppService.getAllBazaar();
        let tempBazaar = await responseJson.data.results.map((row: any) => {
            return {
                label: row.bazaar_name,
                value: row.id,
            }
        })
        setBazaarList(tempBazaar);
        setBazaarList1(tempBazaar);
        setBazaarList2(tempBazaar);

    };

    const handleChange = (selectboxName: string, id: any) => {
        if (selectboxName === 'Bazaar') {
            setBazaarList((prev: any) => {
                return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            });
        }
    }

    const handleChange1 = (selectboxName: string, id: any) => {
        if (selectboxName === 'Bazaar') {
            setBazaarList1((prev: any) => {
                return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            });
        }
    }

    const handleChange2 = (selectboxName: string, id: any) => {
        if (selectboxName === 'Bazaar') {
            setBazaarList2((prev: any) => {
                return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            });
        }
    }


    useEffect(() => {
        console.log("city", city)
    }, [city])

    useEffect(() => {
        if (dateRange?.length == 2) {
            // const date = dateRange.map((item:any)=>`${item.day}/${item.month.number}/${item.year}`).join(",")
            setReportFilter("range");
        }
    }, [dateRange])

    useEffect(() => {
        if (dateRange1?.length == 2) {
            // const date = dateRange1.map((item:any)=>`${item.day}/${item.month.number}/${item.year}`).join(",")
            setMonthReportFilter("range");
        }
    }, [dateRange1])

    useEffect(() => {
        if (dateRange2?.length == 2) {
            // const date = dateRange2.map((item:any)=>`${item.day}/${item.month.number}/${item.year}`).join(",")
            setMonthEarningFilter("range");
        }
    }, [dateRange2])


    return (
        <>
            <div className={classes.root}>

                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} className="mb-5">
                    <FormControl>
                        <InputLabel id="year-select-label" style={{ color: "#84818A", marginTop: "-8px" }}>Select Year</InputLabel>
                        <Select
                            labelId="year-select-label"
                            id="year-select"
                            value={selectedYear}
                            onChange={(e: any) => handleYearChange(e, setSelectedYear)}
                            className="year-selection"
                            style={{ padding: "3px 15px", paddingLeft: "5px" }}
                        >
                            {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                        <CommonSelectElectronicBazaar
                            label={"Bazaar"}
                            hint={"Select Bazaar"}
                            options={bazaarList}
                            handleSelect={handleChange}
                            selectedIds={bazaarList.filter((item: any) => item?.status).map((elm: any) => elm.value)}
                        />
                    </div>
                    <img className="brandLogo" src={filterbtn} alt={"Logo"} style={{ paddingRight: "15px" }} />
                    <button className="updatebtn" onClick={handleReportUpdate}>Update</button>
                </div>

                <div className="flex gap-2" style={{ flexWrap: "wrap" }}>
                    <ActionButton variant={reportFilter === 'today' ? "primary" : "default"} title={"Today"} onClick={() => setReportFilter('today')} />
                    <ActionButton variant={reportFilter === 'this_week' ? "primary" : "default"} title={"This Week"} onClick={() => setReportFilter('this_week')} />
                    <ActionButton variant={reportFilter === 'last_week' ? "primary" : "default"} title={"Last Week"} onClick={() => setReportFilter('last_week')} />
                    <ActionButton variant={reportFilter === 'this_month' ? "primary" : "default"} title={"This Month"} onClick={() => setReportFilter('this_month')} />
                    <ActionButton variant={reportFilter === 'last_month' ? "primary" : "default"} title={"Last Month"} onClick={() => setReportFilter('last_month')} />

                    <DatePicker render={<input type="text" readOnly className="h-[40px] cursor-pointer w-[194px] text-[#84818A] text-[14px] font-[Manrope] font-[600] outline-none border border-[#EBEAED] px-[5px]" placeholder="Select Date Range" />} value={dateRange} dateSeparator=" to " format="DD/MM/YYYY" range onChange={setDateRange} />
                </div>

                <div className="py-[30px]">
                    <p className="py-[20px]">Earnings</p>
                    <div style={{ background: "#F7F7F7" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} style={{ paddingTop: 0 }}>
                                <div style={{ padding: "20px 24px", borderRight: "1px solid #e1e1e1" }}>
                                    <p className="statusTitle" style={{ color: "#2E2C34" }}>Wholesalers</p>
                                    <div className="flex gap-3 pt-[15px]">
                                        <p className="blancetitle">{wholeSellerData?.total_wholeseller_count}</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: 0 }}>
                                <div style={{ padding: "20px 24px" }}>
                                    <p className="statusTitle" style={{ color: "#2E2C34" }}>Commission Earned</p>
                                    <div className="flex gap-3 pt-[15px]">
                                        <p className="blancetitle">{formatIndianRupee(earningData?.TotalCommission || 0)}</p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container spacing={2} style={{ marginRight: 0, width: "100%", marginTop: "30px" }}>
                        {/*City-Wise Wholesalers*/}
                        <Grid item lg={4} md={6} sm={12} xs={12} style={{ paddingTop: 0 }}>
                            <div className="citycontainer p-[20px]" style={{ paddingTop: 0 }}>
                                <div className="flex justify-between py-[20px]">
                                    <p className="cityThead">City-Wise Wholesalers</p>

                                    {/* <p className="citydrop cursor-pointer">Delhi-NCR</p> */}

                                    <Select
                                        labelId="city-select-label"
                                        id="city-select"
                                        value={district}
                                        onChange={(e: any) => setDistrict(e.target.value)}
                                        // onSelect={(e:any)=>console.log("regrg",e)}
                                        variant="standard"
                                        className="city-selection"
                                        style={{ paddingLeft: "5px", width: "120px" }}
                                        placeholder="Select City"
                                    >
                                        {
                                            districts?.map((item: any, index: number) => (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            ))
                                        }

                                    </Select>

                                </div>

                                <div>
                                    <table className="w-full">
                                        <thead>
                                            <tr className="border-b">
                                                <th className="text-start py-3 cityTablehead">Cities</th>
                                                <th className="text-center py-3 cityTablehead">Wholesellers</th>
                                                <th className="text-end py-3 cityTablehead">Commision Earned</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cityWiseWholeSellers?.map((item, index) => (
                                                    <tr>
                                                        <td className="py-2 cityTabledata">{cities?.find((ele: any) => ele.value === item.wholeseller_city)?.label}</td>
                                                        <td className="text-center py-3 cityTabledata">{item?.wholeseller_count}</td>
                                                        <td className="text-end py-3 cityTabledata">{formatIndianRupee(item.commission)}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>

                                    {/* <div className="flex gap-2 py-[10px] cursor-pointer items-center">
                                        <p className="cityButton">View More Customers</p>
                                        <AiOutlineArrowRight style={{ color: "#4E2FA9" }} />
                                    </div> */}
                                </div>
                            </div>
                        </Grid>
                        {/*Plan Expiry*/}
                        <Grid item lg={4} md={6} sm={12} xs={12} style={{ paddingTop: 0 }}>
                            <div className="citycontainer p-[20px]" style={{ paddingBottom: 0, paddingTop: 0 }}>
                                <div className="flex justify-between py-[20px]">
                                    <p className="cityThead">Plan Expiry</p>
                                    <BsThreeDotsVertical className="cursor-pointer" />
                                </div>

                                {
                                    planExpiry.map((item: any, index) => (
                                        <div className="flex justify-between py-[5px]">
                                            <div className="flex gap-4">
                                                <div>
                                                    <Avatar src={item?.wholeseller_image} sx={{ width: 32, height: 32, fontSize: "12px", borderRadius: 5 }}>{item?.wholeseller_name[0]}</Avatar>
                                                </div>
                                                <div>
                                                    <p className="planTitle">{item?.wholeseller_name}</p>
                                                    <p className="planSubtitle">{cities?.find((ele: any) => ele.value === item.wholeseller_city)?.label || "--"}</p>
                                                </div>
                                            </div>

                                            {
                                                (item.status === 'expired') &&
                                                <p className="text-[#ff4343] font-[12px] bg-[#ffeded] p-[9px] rounded-md cursor-pointer" style={{ fontSize: "12px", lineHeight: "18px", paddingBottom: 0, height: "30px", paddingTop: 0, alignItems: "center", display: "flex" }}>
                                                    Expired
                                                </p>

                                            }

                                            {
                                                (item.status === 'No Plan') &&
                                                <p className="text-[#9e9e9e] font-[12px] bg-[#e4e4e4] p-[9px] rounded-md cursor-pointer" style={{ fontSize: "12px", lineHeight: "18px", paddingBottom: 0, height: "30px", paddingTop: 0, alignItems: "center", display: "flex" }}>
                                                    No Plan
                                                </p>

                                            }

                                            {
                                                (item.status === 'expiring_soon') &&
                                                <p className="text-[#FFA043] font-[12px] bg-[#FFF6ED] p-[9px] rounded-md cursor-pointer" style={{ fontSize: "12px", lineHeight: "18px", paddingBottom: 0, height: "30px", paddingTop: 0, alignItems: "center", display: "flex" }}>
                                                    Expiring Soon
                                                </p>

                                            }

                                        </div>
                                    ))
                                }

                                {/* <div className="flex gap-2 mt-[15px] border-t-2 py-[20px] cursor-pointer items-center justify-center">
                                    <p className="cityButton">View More Customers</p>
                                    <AiOutlineArrowRight style={{ color: "#4E2FA9" }} />
                                </div> */}
                            </div>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12} style={{ paddingTop: 0 }}>
                            <div className="citycontainer p-[20px]" style={{ paddingBottom: 0, paddingTop: 0 }}>
                                <div className="flex justify-between py-[20px]">
                                    <p className="cityThead">Transaction History</p>
                                    <BsThreeDotsVertical className="cursor-pointer" />
                                </div>
                                
                                {
                                    transactionHistory.map((item: any) => (
                                        <div className="flex justify-between py-[5px]">
                                            <div className="flex gap-4" style={{ alignItems: "center" }}>
                                                <div>
                                                {
                                                    item.status === 'success' ?
                                                    <Avatar sx={{ width: 24, height: 24, fontSize: "12px", borderRadius: 5, background: "#20C9AC", }}>
                                                        <GrFormCheckmark className="text-white" />
                                                    </Avatar>
                                                    : item.status === 'pending' ?
                                                    <Avatar sx={{ width: 24, height: 24, fontSize: "12px", borderRadius: 5, background: "#FFA043", }}>
                                                        <Info className="text-white" />
                                                    </Avatar>
                                                    : item.status === 'failed' ?
                                                    <Avatar sx={{ width: 24, height: 24, fontSize: "12px", borderRadius: 5, background: "#ff0000", }}>
                                                        <Close className="text-white" />
                                                    </Avatar>
                                                    : ""
                                                }
                                                    
                                                </div>
                                                <div>
                                                    <p className="planTitle">{item?.message}</p>
                                                    <p className="planSubtitle">{moment(item.created_at).format("MMM DD, YYYY, H:MM A")}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="planTitle text-end">{formatIndianRupee(item?.amount)}</p>
                                                {
                                                    item.status === 'success' ?
                                                    <p className="text-[#20C9AC] text-end" style={{ fontSize: "12px" }}>Success</p>
                                                    : item.status === 'pending' ?
                                                    <p className="text-[#FFA043] text-end" style={{ fontSize: "12px" }}>Pending</p>
                                                    : item.status === 'failed' ?
                                                    <p className="text-[#ff0000] text-end" style={{ fontSize: "12px" }}>Failed</p> : ""
                                                }
                                            </div>
                                        </div>
                                    ))
                                }


                                {/* <div className="flex gap-2 mt-[15px]  py-[20px] cursor-pointer items-center justify-center">
                                    <p className="cityButton">View More Customers</p>
                                    <AiOutlineArrowRight style={{ color: "#4E2FA9" }} />
                                </div> */}
                            </div>
                        </Grid>


                    </Grid>
                </div>

                <div style={{ margin: "0 10px" }}>
                    <div className="citycontainer p-[20px]" style={{ padding: "30px" }}>
                        <div className="flex justify-between" style={{ alignItems: "center", paddingBottom: "30px", flexWrap: "wrap" }}>
                            <p className="wtitle">Month wise Report</p>
                            <div style={{ display: "flex", overflowX: "auto" }}>
                                <button className={activeButton === 'wholesaler' ? 'monthwise-btn active' : 'monthwise-btn'}
                                    onClick={() => setActiveButton('wholesaler')}>Wholesaler</button>
                                <button className={activeButton === 'earnings' ? 'monthwise-disable-btn active' : 'monthwise-disable-btn'}
                                    onClick={() => setActiveButton('earnings')}>Earnings</button>
                            </div>
                            <p className="wtitle text-[#4E2FA9] cursor-pointer" style={{ fontSize: "14px", color: "#4E2FA9" }}>View all</p>
                        </div>
                        {activeButton === 'wholesaler' && (
                            <div className="wholesaler-div">
                                {/* Content for the wholesaler div */}
                                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                    <FormControl>
                                        <InputLabel id="year-select-label" style={{ color: "#84818A", marginTop: "-8px" }}>Select Year</InputLabel>
                                        <Select
                                            labelId="year-select-label"
                                            id="year-select"
                                            value={selectedYear1}
                                            onChange={(e: any) => handleYearChange(e, setSelectedYear1)}
                                            className="year-selection"
                                            style={{ padding: "3px 15px", paddingLeft: "5px" }}
                                        >
                                            {years.map((year) => (
                                                <MenuItem key={year} value={year}>
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                        <CommonSelectElectronicBazaar
                                            label={"Bazaar"}
                                            hint={"Select Bazaar"}
                                            options={bazaarList1}
                                            handleSelect={handleChange1}
                                            selectedIds={bazaarList1.filter((item: any) => item?.status).map((elm: any) => elm.value)}
                                        />
                                    </div>
                                    <img className="brandLogo" src={filterbtn} alt={"Logo"} style={{ paddingRight: "15px" }} />
                                    <button className="updatebtn" onClick={handleWholeSellerUpdate}>Update</button>
                                </div>
                                <div>
                                    <div className="flex gap-2 py-[30px]" style={{ flexWrap: "wrap" }}>
                                        <ActionButton variant={monthReportFilter === 'today' ? "primary" : "default"} title={"Today"} onClick={() => setMonthReportFilter('today')} />
                                        <ActionButton variant={monthReportFilter === 'this_week' ? "primary" : "default"} title={"This Week"} onClick={() => setMonthReportFilter('this_week')} />
                                        <ActionButton variant={monthReportFilter === 'last_week' ? "primary" : "default"} title={"Last Week"} onClick={() => setMonthReportFilter('last_week')} />
                                        <ActionButton variant={monthReportFilter === 'this_month' ? "primary" : "default"} title={"This Month"} onClick={() => setMonthReportFilter('this_month')} />
                                        <ActionButton variant={monthReportFilter === 'last_month' ? "primary" : "default"} title={"Last Month"} onClick={() => setMonthReportFilter('last_month')} />
                                        {/* <input type='date' style={{ border: "1px solid #e1e1e1", fontSize: "14px", borderRadius: "4px", padding: "5px 15px" }} onChange={(e:any)=>setMonthReportFilter(e.target.value)} /> */}

                                        <DatePicker render={<input type="text" readOnly className="h-[40px] cursor-pointer w-[194px] text-[#84818A] text-[14px] font-[Manrope] font-[600] outline-none border border-[#EBEAED] px-[5px]" placeholder="Select Date Range" />} value={dateRange1} dateSeparator=" to " format="DD/MM/YYYY" range onChange={setDateRange1} />
                                    </div>
                                </div>

                                <Grid container spacing={2}>
                                    <Grid item lg={5} md={6} sm={12} xs={12} style={{ paddingTop: "15px" }}>
                                        <div className="bg-[#F9F5F2] flex justify-between p-[30px] rounded-md">
                                            <p className="agentpaymentTitle">Year - 2021</p>
                                            <div>
                                                <p className="statusTitle" style={{ color: "#2E2C34" }}>Total Customers</p>
                                                <div className="flex gap-3 pt-[15px]">
                                                    <p className="blancetitle">{monthWiseWholeseller.total_wholeseller_count}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className="py-[40px]">
                                    <Grid container spacing={2}>
                                        {monthWiseWholeseller.wholesellers?.map((item: any, index: number) => {
                                            return <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                                                <div className="bg-[#FF6652] p-[15px] rounded-t-md">
                                                    <p className="text-center calanderHead">{item.month}</p>
                                                </div>
                                                <div className="border-x-2 border-b-2 rounded-b-md">
                                                    <div className="p-[30px] text-center">
                                                        <p className="calanderTitle">No. of Wholesalers</p>
                                                        <p className="calanderSubtitle">{item.count}</p>
                                                    </div>
                                                </div>
                                            </Grid>
                                        })}
                                    </Grid>
                                </div>
                            </div>
                        )}

                        {activeButton === 'earnings' && (
                            <div className="earnings-div">
                                {/* Content for the earnings div */}
                                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                    <FormControl>
                                        <InputLabel id="year-select-label" style={{ color: "#84818A", marginTop: "-8px" }}>Select Year</InputLabel>
                                        <Select
                                            labelId="year-select-label"
                                            id="year-select"
                                            value={selectedYear2}
                                            onChange={(e: any) => handleYearChange(e, setSelectedYear2)}
                                            className="year-selection"
                                            style={{ padding: "3px 15px", paddingLeft: "5px" }}
                                        >
                                            {years.map((year) => (
                                                <MenuItem key={year} value={year}>
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                        <CommonSelectElectronicBazaar
                                            label={"Bazaar"}
                                            hint={"Select Bazaar"}
                                            options={bazaarList2}
                                            handleSelect={handleChange2}
                                            selectedIds={bazaarList2.filter((item: any) => item?.status).map((elm: any) => elm.value)}
                                        />
                                    </div>
                                    <img className="brandLogo" src={filterbtn} alt={"Logo"} style={{ paddingRight: "15px" }} />
                                    <button className="updatebtn" onClick={handleEarningUpdate}>Update</button>
                                </div>
                                <div>
                                    <div className="flex gap-2 py-[30px]" style={{ flexWrap: "wrap" }}>
                                        <ActionButton variant={monthEarningFilter === 'today' ? "primary" : "default"} title={"Today"} onClick={() => setMonthEarningFilter('today')} />
                                        <ActionButton variant={monthEarningFilter === 'this_week' ? "primary" : "default"} title={"This Week"} onClick={() => setMonthEarningFilter('this_week')} />
                                        <ActionButton variant={monthEarningFilter === 'last_week' ? "primary" : "default"} title={"Last Week"} onClick={() => setMonthEarningFilter('last_week')} />
                                        <ActionButton variant={monthEarningFilter === 'this_month' ? "primary" : "default"} title={"This Month"} onClick={() => setMonthEarningFilter('this_month')} />
                                        <ActionButton variant={monthEarningFilter === 'last_month' ? "primary" : "default"} title={"Last Month"} onClick={() => setMonthEarningFilter('last_month')} />
                                        {/* <input type='date' style={{ border: "1px solid #e1e1e1", fontSize: "14px", borderRadius: "4px", padding: "5px 15px" }} onChange={(e:any)=>setMonthReportFilter(e.target.value)} /> */}

                                        <DatePicker render={<input type="text" readOnly className="h-[40px] cursor-pointer w-[194px] text-[#84818A] text-[14px] font-[Manrope] font-[600] outline-none border border-[#EBEAED] px-[5px]" placeholder="Select Date Range" />} value={dateRange2} dateSeparator=" to " format="DD/MM/YYYY" range onChange={setDateRange2} />
                                    </div>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item lg={5} md={6} sm={12} xs={12} style={{ paddingTop: "15px" }}>
                                        <div className="bg-[#F9F5F2] flex justify-between p-[30px] rounded-md">
                                            <p className="agentpaymentTitle">Year - 2021</p>
                                            <div>
                                                <p className="statusTitle" style={{ color: "#2E2C34" }}>Total Earnings</p>
                                                <div className="flex gap-3 pt-[15px]">
                                                    <p className="blancetitle">{formatIndianRupee(monthWiseEarning.TotalCommission || 0)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className="py-[40px]">
                                    <Grid container spacing={2}>
                                        {monthWiseEarning.Earning?.map((item: any, index: number) => {
                                            return <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                                                <div className="bg-[#FF6652] p-[15px] rounded-t-md">
                                                    <p className="text-center calanderHead">{item.month}</p>
                                                </div>
                                                <div className="border-x-2 border-b-2 rounded-b-md">
                                                    <div className="p-[30px] text-center">
                                                        <p className="calanderTitle">Total Earnings</p>
                                                        <p className="calanderSubtitle">{formatIndianRupee(item.commission || 0)}</p>
                                                    </div>
                                                </div>
                                            </Grid>
                                        })}
                                    </Grid>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div >
        </>
    );
};

export default Agentreport;
