import React, { useEffect, useState } from "react";
import { useSummarycardStyle } from "@/static/stylesheets/molecules/summarycardStyle";
import { AppService } from "../../../../service/AllApiData.service";
import { formatIndianRupee } from "@/screens/AgentDetails/AgentReport";

interface SummaryCardType{
  year?: string
}

const SummaryCard: React.FC<SummaryCardType>  = ({year}) => {
  const [getTotalBazaar, setTotalBazaar] = useState("");
  const [getWholesellers, setWholesellers] = useState("");
  const [getRevenue, setRevenue] = useState("");
  const [getBill, setBill] = useState("");
  const [getAgent, setAgent] = useState("");
  const [getCommission, setCommission] = useState("");
  const [getCustomer, setCustomer] = useState("");
  const localStoragedata = JSON.parse(localStorage.getItem("agency") || '')
  const [totaleagent, settotaleagent] = useState<any>([]);
  const [dashboardSummary, setDashboardSummary] = useState<any>();


  // const totaleEarningCount = async(id:any) =>{
  //   let data = await AppService.totaleEarningCount(id)
  //   console.log(data?.data)
  //   setCommission(data?.data?.TotalCommission)
  //  }


  const getDashboardSummary = async (year:any="") => {
    try {
      let param = {};
      if(year){
        param = {y:year}
      }
      const response = await AppService.getAllSummerys(param);
      console.log("Dashboard Summary...", response)
      setDashboardSummary(response.data)
      
    } catch (err:any) {
      console.log("ERROR ====>",err.message)
    }
  }

  useEffect(() => {
    getDashboardSummary(year);
  }, [year]);
  const classes = useSummarycardStyle();

  return (
    <div className={classes.root}>
      <div className={classes.bazaarCard}>
        <p className="headTitle">Total Bazar</p>
        <p className="headSubtitle">{dashboardSummary?.bazaar || 0}</p>
      </div>

      <div className={classes.bazaarCard}>
        <p className="headTitle">Total Agents</p>
        <p className="headSubtitle">{dashboardSummary?.agent || 0}</p>
      </div>

      <div className={classes.bazaarCard}>
        <p className="headTitle">Total Wholesellers</p>
        <p className="headSubtitle">{dashboardSummary?.wholeseller || 0}</p>
      </div>

      <div className={classes.bazaarCard}>
        <p className="headTitle">Total Commission Earned</p>
        <p className="headSubtitle">{formatIndianRupee(dashboardSummary?.commission || 0)}</p>
      </div>
    </div>
  );
};

export default SummaryCard;
