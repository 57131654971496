import React, { useState, useEffect } from "react";
import LogoPrev from "@/static/icons/ic_previous.png";
import {
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Box,
    Grid,
    Checkbox,
    ListItemText
} from "@mui/material";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import DistrictPicker from "@/components/atoms/LocationPickers/DistrictPicker";
import { useNavigate, useParams } from "react-router-dom";
import { AppService } from "@/service/AllApiData.service";
import CommonSelect from "@/components/atoms/CommonSelect/CommonSelect";
import { CityPickerNew } from "@/components/atoms/LocationPickers/CityPickerNew";
import { ErrorMessage, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Alert, AlertError } from "@/alert/Alert";
import moment from "moment";
import { DashboardLayout } from "@/components/layouts";
import { SectionHeader } from "../OurAgents/SectionHeader";
import { addouragentStyle } from "@/static/stylesheets/molecules";
import PhoneInput from "react-phone-input-2";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function AddOurAgents() {
    const navigate = useNavigate();
    const classes = addouragentStyle();
    const [masterType, setMasterType] = useState("Regional Wholesaler");
    const [stateList, setStateList] = useState<any[]>([]);
    const [stateList1, setStateList1] = useState<any[]>([]);
    const [bazaarList, setBazaarList] = useState([]);
    const [bazaar, setBazaar] = useState<any>();
    const [states, setStates] = useState<any>();
    const [states1, setStates1] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [districts1, setDistricts1] = useState<any>();
    const [districtList, setDistrictList] = useState<any[]>([]);
    const [districtList1, setDistrictList1] = useState<any[]>([]);
    const [bazaars, setBazaars] = useState<any[]>([]);
    const [cityList, setCityList] = useState<any[]>([]);
    const [cityList1, setCityList1] = useState<any[]>([]);
    const [citys, setCitys] = useState<any>();
    const [wholesellerType, setWholesellerType] = useState<any[]>([]);
    const [WholesellerAgent, setWholesellerAgent] = useState<any[]>([]);
    const [Wholesellerretailer, setWholesellerretailer] = useState<any[]>([]);
    const [bazaarWiseData, setBazaarWiseData] = React.useState<any>([]);
    const [data, setData] = useState<any>({});
    const { id } = useParams()
    const localStoragedata = JSON.parse(localStorage.getItem("agency") || "")
    const [initialValues, setinitialValues] = useState<any>({
        agent_name: "",
        agent_number: "",
        agent_state: "",
        agent_city: "",
        agent_district: "",
        agent_bazaar: [],
        agent_assigned_state: "",
        agent_assigned_district: "",
        agent_assigned_city: "",
        agency: localStorage.getItem("agency") ? JSON.parse(localStorage.getItem("agency") || "").agency_id : "",
    });
    const agency = localStorage.getItem("agency") ? JSON.parse(localStorage.getItem("agency") || "") : {};

    const allowedBazaars = agency.agent_bazaar_data.map((item: any, index: number) => (
        {
            label: item.bazaar_name,
            value: item.id
        }
    ))
    const allowedStates = agency?.bazaar_assgined_data.flatMap((item: any) => item.state.map((ele: any, index: number) => (
        {
            label: item.state_names[index],
            value: ele
        }
    )))

    const allowedDistrict = agency?.bazaar_assgined_data.flatMap((item: any) => item.district.map((ele: any, index: number) => (
        {
            label: item.district_names[index],
            value: ele
        }
    )))

    const allowedCity = agency?.bazaar_assgined_data.flatMap((item: any) => item.city.map((ele: any, index: number) => (
        {
            label: item.city_names[index],
            value: ele
        }
    )))

    const getagentdata = async () => {
        if (id) {
            const responseJson = await AppService.getagentdata(id);
            setinitialValues({
                ...responseJson.data,
                agent_bazaar: responseJson.data.agent_bazaar,
                // agent_assigned_state: responseJson.data.agent_assigned_state[0],
                // agent_assigned_district: responseJson.data.agent_assigned_district[0],
                // agent_assigned_city: responseJson.data.agent_assigned_city[0],
            })
            setStates(responseJson.data.agent_state)
            setDistricts(responseJson.data.agent_district)
            setBazaar(responseJson.data.agent_bazaar)
            setBazaarWiseData(responseJson.data.bazaar_assgined_data)
            // setStates1(responseJson.data.agent_assigned_state[0])
            // setDistricts1(responseJson.data.agent_assigned_district[0])
            // setBazaarWiseData(responseJson.data.bazaar_assgined_data);
        }
    }

    useEffect(() => {
        if (id) {
            getagentdata()
        }
    }, [id])


    console.log(initialValues, "datadata")

    useEffect(() => {
        getTotalStates();
        getAllBazaar();
        getWholesellerType();
        getWholesellerAgent();
        getWholesellerretailer({ ids: data?.wholeseller_city });
    }, []);

    useEffect(() => {
        getDistrictByStates();
    }, [states]);


    const getStatesByBazaar = async (params: any) => {
        setStateList1([]);
        setDistrictList1([]);
        setCityList1([]);
        try {
            const responseJson = await AppService.getAllStateByBazaar(params);

            const allowedIds = allowedStates.map((item: any) => item.value)

            const tempState = responseJson.data.results.flatMap((row: any) =>
                row.bazaar_state.map((id: any) => {
                    const stateItem = stateList.find((item: any) => item.id === id);
                    if (stateItem && allowedIds.includes(id)) {
                        return {
                            label: stateItem.state,
                            value: id,
                        };
                    }
                    return null // Handle cases where a matching state is not found
                })
            ).filter((item: any) => item !== null);

            console.log("logger123...", allowedIds, tempState);

            setStateList1(tempState);
        } catch (error) {
            // Handle errors here
            console.error(error);
        }
    };

    useEffect(() => {
        getStatesByBazaar({ ids: bazaar })
    }, [bazaar]);

    useEffect(() => {
        getDistrictByStates1();
    }, [states1]);

    useEffect(() => {
        console.log("DISTRICTS =====> ",districts)
        if (districts) {
            getAllCity({ids:districts}, "single");
        }
    }, [districts]);

    useEffect(() => {
        getAllCity1();
    }, [districts1]);

    const handleChangeMasterType = (event: SelectChangeEvent) => {
        setMasterType(event.target.value as string);
    };

    const getTotalStates = async () => {
        const responseJson = await AppService.getTotalStates();
        setStateList(responseJson.data);
        // setStateList1(responseJson.data);
    };

    const getDistrictByStates = async () => {
        const responseJson = await AppService.getAllDistricByState({ ids: states });
        setDistrictList(responseJson.data.results);
        setCityList([])
    };

    const getDistrictByStates1 = async () => {
        const responseJson = await AppService.getAllDistricByState({ ids: states1 });

        const allowedIds = allowedDistrict.map((item: any) => item.value);
        const dists = responseJson.data.results.flatMap((ele: any) => {
            return ele.district.map((item: any) => {
                if (allowedIds.includes(item.id)) {
                    return {
                        label: item.district,
                        value: item.id
                    }
                }
                return null;
            })
        }).filter((item: any) => item !== null && item !== undefined)
        console.log("allowed", allowedIds, dists)
        setDistrictList1(dists);
    };

    const getAllCity = async (param: any, type: any, index: number = 0) => {
        const responseJson = await AppService.getAllCityByDis(param);
        if (type == "multiple") {
            bazaarWiseData[index].allCities = responseJson.data.results;
            setBazaarWiseData([...bazaarWiseData]);
        } else {
            setCityList(responseJson.data.results);
        }
    };

    const getAllCity1 = async () => {
        const responseJson = await AppService.getAllCityByDis({ ids: districts1 });
        console.log("districts", responseJson.data.results);
        const allowedIds = allowedCity.map((item: any) => item.value);
        const cities = responseJson.data.results.flatMap((ele: any) => {
            return ele.city.map((item: any) => {
                if (allowedIds.includes(item.id)) {
                    return {
                        label: item.city,
                        value: item.id
                    }
                }
                return null;
            })
        }).filter((item: any) => item !== null && item !== undefined)
        console.log("allowed", allowedIds, cities)
        setCityList1(cities);
    };

    const getWholesellerType = async () => {
        const responseJson = await AppService.getWholesellerType({});
        console.log("responseJson.data.results", responseJson.data.results);
        setWholesellerType(responseJson.data.results);
    };
    const getWholesellerAgent = async () => {
        const responseJson = await AppService.getAllAgentList();
        setWholesellerAgent(responseJson.data.results);
    };
    const getWholesellerretailer = async (param: any) => {
        const responseJson = await AppService.getRetailerType(param);
        setWholesellerretailer(responseJson.data.results);
    };

    const AgentSchema = Yup.object().shape({
        agent_name: Yup.string()
            .min(2, "Too Short!")
            .max(40, "Too Long!")
            .required("Agent name is required"),
        agent_number: Yup.number()
            .positive("Phone no must be positive")
            .required("Phone no is required"),
        agent_state: Yup.string().required("State is required"),
        agent_district: Yup.string().required("District is required"),
        agent_city: Yup.string().required("City is required"),
        agent_bazaar: Yup.number().required("Bazaar is required"),
        // agent_assigned_state: Yup.number().required("Agent Assigned State is required"),
        // agent_assigned_district: Yup.number().required("Agent Assigned District is required"),
        // agent_assigned_city: Yup.number().required("Agent Assigned City is required")
    });


    const handleFormSubmit = (values: any) => {
        console.log("handleFormvaluesSubmit", values);
    }

    const handleFormSubmit1 = async (values: any) => {
        console.log("handleFormSubmit");
    }


    const getAllBazaar = async () => {
        const responseJson = await AppService.getAllBazaar();
        const allowedIds = allowedBazaars.map((item: any) => item.value)
        let tempBazaar = await responseJson.data.results.map((row: any) => {
            if (allowedIds.includes(row.id))
                return {
                    label: row.bazaar_name,
                    value: row.id,
                    ...row
                }
            return null;
        }).filter((item: any) => item !== null)
        setBazaarList(tempBazaar);
    };

    // const handleChange = (e: any) => {
    //     if (e.target.name == "agent_bazaar" || e.target.name == "agent_assigned_state" || e.target.name == "agent_assigned_district" || e.target.name == "agent_assigned_state" || e.target.name == "agent_assigned_city") {
    //         setinitialValues((prev: any) => ({ ...prev, [e.target.name]: [e.target.value] }))
    //     }
    //     else {
    //         setinitialValues((prev: any) => ({ ...prev, [e.target.name]: e.target.value }))
    //     }
    // }


    const getAllDis = async (param: any, type: any, index: number = 0) => {
        console.log("ppppppppparrararams", param);

        const responseJson = await AppService.getAllDistricByState(param);
        console.log("all Districtss and single-----");
        // console.log(responseJson.data.bazaar);
        // setAllDis(responseJson.data.results);

        if (type == "multiple") {
            console.log("all Districtss and single-----ALLL");
            if (bazaarWiseData[index]?.allDistricts) {
                bazaarWiseData[index].allDistricts = responseJson.data.results;
                setBazaarWiseData([...bazaarWiseData]);
            }
        } else {
            console.log("all Districtss and single-----SINGLL");
            //   setSingleDis(responseJson.data.results);
        }
    };


    const hendelonsubmit = async (values: any) => {
        let body = { ...values };
        // body.agent_bazaar = [body.agent_bazaar]
        // body.agency = agency.id
        body.bazaar_assgined_data = bazaarWiseData;
        delete body.agent_image;
        delete body.agent_adhar_front_image;
        delete body.agent_adhar_back_image;
        delete body.agent_pancard_image;
        delete body.agency;
        // body.agent_assigned_state = [body.agent_assigned_state]
        // body.agent_assigned_district = [body.agent_assigned_district]
        // body.agent_assigned_city = [body.agent_assigned_city]

        console.log("LOGGER123....", body);
        // return;
        if (id) {
            let data = await AppService.editagent(body);
            if (data.status == 200) {
                Alert("Successfully agent Edit!")
                navigate('/ourAgents')
            }
        } else {
            let agency = localStorage.getItem("agency") ? JSON.parse(localStorage.getItem("agency") || "") : {};

            body.agency = agency.agency;
            let data = await AppService.createagent(body);
            if (data.status == 201) {
                Alert("Successfully agent created!")
                navigate('/ourAgents')
            }
        }
    }

    const setBranchPhonefun = (e: any) => {
        initialValues.agent_number = `+${e}`
    }



    const handleStateChange = (list: any, e: any, index: any, city = false, type: any = "") => {


        // const allLength = city ? allCity.flatMap((item:any)=>item.city.map((item:any)=>item.id)) : allDis.flatMap((item:any)=>item.district.map((item:any)=>item.id))
        // console.log("list.....123...",e, list, type, list.filter((item:any)=>!e.includes(item)) )

        function removeDuplicates<T>(array: T[]): T[] {
            return array.filter((value, index, self) => self.indexOf(value) === index);
        }

        if (e.length > 1) {
            if (type?.all === true) {
                if (city) {
                    bazaarWiseData[index].city = removeDuplicates([...list, ...e]);
                    setBazaarWiseData([...bazaarWiseData]);
                } else {
                    bazaarWiseData[index].district = removeDuplicates([...list, ...e]);
                    setBazaarWiseData([...bazaarWiseData]);
                    getAllCity({ ids: bazaarWiseData[index].district?.join(",") }, "multiple", index);
                }
            } else {
                if (city) {
                    bazaarWiseData[index].city = [...list.filter((item: any) => !e.includes(item))];
                    setBazaarWiseData([...bazaarWiseData]);
                } else {
                    bazaarWiseData[index].district = [...list.filter((item: any) => !e.includes(item))];
                    setBazaarWiseData([...bazaarWiseData]);
                    getAllCity({ ids: bazaarWiseData[index].district?.join(",") }, "multiple", index);
                }
            }
        } else {
            if (list.includes(e[0])) {
                if (city) {
                    bazaarWiseData[index].city = [...list.filter((item: any) => item !== e[0])];
                    setBazaarWiseData([...bazaarWiseData]);
                } else {
                    bazaarWiseData[index].district = [...list.filter((item: any) => item !== e[0])];
                    setBazaarWiseData([...bazaarWiseData]);
                    getAllCity({ ids: bazaarWiseData[index].district?.join(",") }, "multiple", index);
                }
            } else {

                if (city) {
                    bazaarWiseData[index].city = [...list, e[0]];
                    setBazaarWiseData([...bazaarWiseData]);
                } else {
                    bazaarWiseData[index].district = [...list, e[0]];
                    setBazaarWiseData([...bazaarWiseData]);
                    getAllCity({ ids: bazaarWiseData[index].district?.join(",") }, "multiple", index);
                }

            }
        }


        console.log("logger123..", list)
    }

    return (
        <DashboardLayout>
            <SectionHeader />
            <div className={`${classes.root} add_branch`}>

                <Formik
                    initialValues={initialValues}
                    validationSchema={AgentSchema}
                    enableReinitialize={true}
                    onSubmit={hendelonsubmit}
                >
                    {
                        ({ values, handleChange, handleSubmit, setFieldValue }) => (
                            <div>
                                <div className="mb-[53px] flex itens-center">
                                    <div className="cursor-pointer flex me-5" onClick={() => navigate('/ourAgents')}>
                                        <ArrowBackIosNewIcon />
                                    </div>
                                    <p className="commonTitle">{id ? "Edit Agent" : "Add Agent"}</p>
                                </div>
                                <div className="formContainer mb-[32px]">
                                    <div className="w-1/2">
                                        <label className="branchLabel">Agent  Person</label>
                                        <input type="text" className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full" name="agent_name" onChange={handleChange} value={values.agent_name} />
                                        <div className=" text-red-600">
                                            <ErrorMessage name="agent_name" />
                                        </div>
                                    </div>
                                    <div className="w-1/2">
                                        <PhoneInput
                                            country={"in"}
                                            placeholder="Enter Phone Number"
                                            onChange={(e: any) => setFieldValue("agent_number", e)}
                                            value={values.agent_number}
                                            countryCodeEditable={false}
                                            containerStyle={{
                                                width: "100%",
                                            }}
                                        />
                                        <div className=" text-red-600">
                                            <ErrorMessage name="agent_number" />
                                        </div>
                                    </div>
                                </div>
                                <div className="formContainer mb-[32px]">
                                    <div className="w-1/2">
                                        <label className="branchLabel">State</label>
                                        <Select
                                            label="State"
                                            variant={"standard"}
                                            fullWidth={true}
                                            name="agent_state"
                                            value={values.agent_state}
                                            onChange={(e) => { 
                                                handleChange(e); 
                                                setStates(e.target.value) 
                                                setFieldValue("agent_district","")
                                                setFieldValue("agent_city","")
                                            }}
                                        >
                                            {stateList.map((row: any) => (
                                                <MenuItem value={row.id}>{row.state}</MenuItem>
                                            ))}
                                        </Select>
                                        <div className=" text-red-600">
                                            <ErrorMessage name="agent_state" />
                                        </div>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="branchLabel">Districts</label>
                                        <Select
                                            label="Districts"
                                            variant={"standard"}
                                            fullWidth={true}
                                            name="agent_district"
                                            value={values.agent_district}
                                            onChange={(e) => { 
                                                handleChange(e); 
                                                setDistricts(e.target.value)
                                                setFieldValue("agent_city","")
                                            }}
                                        >
                                            {districtList.map((row: any) => (
                                                row.district.map((innerrow: any) => (
                                                    <MenuItem value={innerrow.id}>{innerrow.district}</MenuItem>
                                                ))
                                            ))}
                                        </Select>
                                        <div className=" text-red-600">
                                            <ErrorMessage name="agent_district" />
                                        </div>
                                    </div>
                                </div>
                                <div className="formContainer mb-[32px]">
                                    <div className="w-1/2">
                                        <label className="branchLabel">City</label>
                                        <Select
                                            label="City"
                                            variant={"standard"}
                                            fullWidth={true}
                                            name="agent_city"
                                            value={values.agent_city}
                                            onChange={handleChange}
                                        >
                                            {
                                                cityList.map((row: any) => (
                                                    row.city.map((innerrow: any) => (
                                                        <MenuItem value={innerrow.id}>{innerrow.city}</MenuItem>
                                                    ))
                                                ))
                                            }
                                        </Select>
                                        <div className=" text-red-600">
                                            <ErrorMessage name="agent_city" />
                                        </div>
                                    </div>
                                </div>
                                <div className="formContainer mb-[32px]">

                                    <div className="w-1/2">
                                        <p className="title-main">Bazaar <span className=" text-red-600">*</span></p>
                                        <Select
                                            label="Bazaar"
                                            multiple={true}
                                            variant={"standard"}
                                            fullWidth={true}
                                            value={values.agent_bazaar}
                                            // onBlur={handleBlur}
                                            name="agent_bazaar"
                                            onChange={(e) => {
                                                let data: any = [];
                                                e.target.value?.map((item: any, index: number) => {
                                                    // if(data[index]?.bazaar_id !== item){
                                                    data = [...data, {
                                                        bazaar_id: item,
                                                        state: [],
                                                        district: [],
                                                        city: [],
                                                        allDistricts: [],
                                                        allCities: []
                                                    }]
                                                    // }
                                                })
                                                setBazaarWiseData(data);
                                                handleChange(e);
                                                setBazaar(e.target.value);
                                                console.log("Bazaar_DATA", e.target.value)
                                                setFieldValue("agent_assigned_state", "")
                                                setFieldValue("agent_assigned_district", [])
                                                setFieldValue("agent_assigned_city", [])

                                            }}
                                            renderValue={(selected) => {
                                                var data: any = [];
                                                bazaarList.map((item: any) => {
                                                    if (selected.includes(item.value)) {
                                                        data.push(item.label)
                                                    }
                                                })
                                                return data.join(', ')
                                            }
                                            }
                                        >
                                            {bazaarList.map((items: any, index: any) => (
                                                <MenuItem key={index} value={items.value}>
                                                    <Checkbox checked={values.agent_bazaar.includes(items.value)} />
                                                    <ListItemText primary={items.label} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Box sx={{ color: "red" }}>
                                            <ErrorMessage name="agent_bazaar" />
                                        </Box>
                                    </div>

                                </div>



                                {
                                    bazaarList.length ? bazaarWiseData.map((item: any, index: number) => {
                                        let bazaar: any = bazaarList.find((ele: any) => ele.value === item.bazaar_id);
                                        console.log("Bazaar LOG ====> ", bazaar, bazaarWiseData, bazaarList, allowedStates)
                                        let allStates: any = bazaar.bazaar_state_names.filter((item: any) => allowedStates.map((ele: any) => ele.value).includes(item.id)).map((item: any) => (
                                            {
                                                label: item.state,
                                                value: item.id
                                            }
                                        ))

                                        let allDistricts: any = bazaar.bazaar_district_names.filter((item: any) => allowedDistrict.map((ele: any) => ele.value).includes(item.id)).filter((item: any) => bazaarWiseData[index]?.state.includes(item.state))

                                        let allCities: any = bazaar.bazaar_city_names.filter((item: any) => allowedCity.map((ele: any) => ele.value).includes(item.id)).filter((item: any) => bazaarWiseData[index]?.district.includes(item.district))

                                        return (

                                            <div className="formContainer flex flex-col">

                                                <p className=" font-bold">{bazaar?.label}</p>

                                                <div className="grid grid-cols-2 gap-4 w-full">

                                                    <div>
                                                        <p className="title-main">Assigned States <span className=" text-red-600">*</span></p>
                                                        <Select
                                                            label="Age"
                                                            variant={"standard"}
                                                            fullWidth={true}
                                                            multiple={true}
                                                            name="agent_assigned_state"
                                                            value={bazaarWiseData[index]?.state}
                                                            // onBlur={handleBlur}
                                                            onChange={(e: any) => {
                                                                bazaarWiseData[index].state = e.target.value;
                                                                bazaarWiseData[index].district = [];
                                                                bazaarWiseData[index].city = [];
                                                                setBazaarWiseData([...bazaarWiseData]);
                                                                handleChange(e);
                                                                getAllDis(
                                                                    {
                                                                        ids: e.target.value.join(",")
                                                                    },
                                                                    "multiple",
                                                                    index
                                                                );
                                                                setFieldValue("agent_assigned_district", [])
                                                                setFieldValue("agent_assigned_city", [])
                                                            }}
                                                            renderValue={(selected) => {
                                                                return allStates.filter((item: any) => selected.includes(item.value)).map((item: any) => item?.label)?.join(", ");
                                                            }
                                                            }
                                                        >
                                                            {allStates?.map((items: any) => (
                                                                <MenuItem key={index} value={items.value}>
                                                                    <Checkbox checked={bazaarWiseData[index]?.state?.includes(items.value)} />
                                                                    <ListItemText primary={items.label} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <Box sx={{ color: "red" }}>
                                                            <ErrorMessage name="agent_assigned_state" />
                                                        </Box>
                                                    </div>

                                                    <div>
                                                        <div className="Selectdistrict">
                                                            <DistrictPicker
                                                                label={"Assigned District"}
                                                                placeholder="Assigned District"
                                                                hint={"Search"}
                                                                variant={"outlined"}
                                                                states={allStates.map((item: any) => ({ state: item.label, id: item.value }))}
                                                                selectedStates={[...bazaarWiseData[index]?.state]}
                                                                districts={bazaarWiseData[index].allDistricts.map((item: any, index: number) => {
                                                                    return (
                                                                        {
                                                                            ...item,
                                                                            district: item?.district?.filter((ele: any) => allDistricts.map((item: any) => item.id).includes(ele.id)) || []
                                                                        }
                                                                    )
                                                                })}
                                                                selectedDistricts={bazaarWiseData[index]?.district}
                                                                onSelectItem={(e: any, type: any) => {
                                                                    handleStateChange(bazaarWiseData[index]?.district, e, index, false, type)
                                                                    bazaarWiseData[index].city = [];
                                                                    setBazaarWiseData([...bazaarWiseData]);
                                                                    setFieldValue("agent_assigned_city", [])
                                                                }}
                                                            />

                                                        </div>

                                                        <Box sx={{ color: "red" }}>
                                                            <ErrorMessage name="agent_assigned_district" />
                                                        </Box>
                                                    </div>
                                                    <div>

                                                        <CityPickerNew 
                                                            label={"Assigned Cities"}
                                                            placeholder="Assigned Cities"
                                                            hint={"Search"}
                                                            variant={"outlined"}
                                                            states={bazaarWiseData[index].allDistricts}
                                                            selectedStates={bazaarWiseData[index]?.district}
                                                            // selectedDistricts={bazaarWiseData[index]?.district}
                                                            districts={bazaarWiseData[index].allCities.map((item: any, index: number) => {
                                                                // console.log("LOGGER123====> efwefwef", bazaarWiseData[index].allCities[index]?.city, allCities)
                                                                return (
                                                                    {
                                                                        ...item,
                                                                        city: item?.city?.filter((ele: any) => allCities.map((item: any) => item.id).includes(ele.id)) || []
                                                                    }
                                                                )
                                                            })}
                                                            selectedCity={bazaarWiseData[index]?.city}
                                                            // selectedcity={bazaarWiseData[index]?.city}
                                                            onSelectItem={(id: any, type: { all: boolean }) => handleStateChange(bazaarWiseData[index]?.city, id, index, true, type)}
                                                        />
                                                        <Box sx={{ color: "red" }}>
                                                            <ErrorMessage name="agent_assigned_city" />
                                                        </Box>
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    }) : null
                                }



                                <div className="flex gap-5 py-5">
                                    <ActionButton title="Cancel" variant="default" onClick={() => { navigate("/ourAgents") }} />
                                    <ActionButton title="Save" variant="primary" onClick={handleSubmit} />
                                </div>
                            </div>
                        )
                    }
                </Formik>
            </div>
        </DashboardLayout>
    )
}

export default AddOurAgents