import React, { useEffect } from "react";
import { Routes } from "@/routes";
import { GlobalStyles } from "@/static/stylesheets";
import { AppService } from "./service/AllApiData.service";
import { ToastContainer } from "react-toastify";
import "react-phone-input-2/lib/style.css";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [isAnyModalOpen, setisAnyModalOpen] = React.useState(false);
  // const getLogin = async () => {
  //   const responseJson = await AppService.login("mwb", "mwb@123");
  // };
  // useEffect(() => {
  //   getLogin();
  // }, []);

  const handleModalBackdrop = (value: any) => {
    setisAnyModalOpen(value);
  };

  return (
    <>
    <div className={isAnyModalOpen ? "app-active" : "app"}>
      <GlobalStyles />
      <Routes handleModalBackdrop={handleModalBackdrop} />
    </div>
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
}

export default App;