import React, { useEffect, useState } from "react";
import { Dialog, Grid, LinearProgress, MenuItem, Select, TextField } from "@mui/material";
import { DashboardLayout } from "@/components/layouts";
import Box from '@mui/material/Box';
import { useDashboardStyles } from "@/static/stylesheets/screens";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import CommonSelect from "@/components/atoms/CommonSelect/CommonSelect";
import { AppService } from "../../service/AllApiData.service";
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateRangePicker } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import DistrictPicker from "@/components/atoms/LocationPickers/DistrictPicker";
import CityPicker from "@/components/atoms/LocationPickers/CityPicker";
import { string } from "yup";
import DatePicker from "react-multi-date-picker";
import type { Value } from "react-multi-date-picker"
import SummaryCard from "@/components/molecules/Dashboard/SummaryCard/SummaryCard";
import { array } from "prop-types";
import { formatIndianRupee } from "../AgentDetails/AgentReport";
import { getDateRange, getFinancialYears, getFormatedDateRange } from "@/helper/date";

const Dashboard = () => {

    const [getAllBazar, setGetAllBazar] = useState([]);
    const [getTotalBazaar, setTotalBazaar] = useState("");
    const [getWholesellers, setWholesellers] = useState("");
    const [getRevenue, setRevenue] = useState("");
    const [getBill, setBill] = useState("");
    const [getAgent, setAgent] = useState("");
    const [getCommission, setCommission] = useState("");
    const [getCustomer, setCustomer] = useState("");
    const [activeTab, setActiveTab] = useState("today")
    const [activeTab1, setActiveTab1] = useState("today")
    const [yearval, setYearval] = useState<any>("2023")
    const [active, setActive] = useState(null)
    const [todayrespo, setTodayrespo] = useState(null)
    const [todayreport, setTodayreport] = useState(null)
    const [reportBazzarlist, setReportBazzarlist] = useState<any>([])
    const [planBazzarlist, setPlanBazzarlist] = useState<any>([])
    const [selectreportbazzar, setselectreportbazzar] = useState<any>([])
    const [selectreportbazzar2, setselectreportbazzar2] = useState<any>([])
    const [getPlanname, setgetPlanname] = useState<any>([])
    const [selectplanname, setselectplanname] = useState<any>([])
    const [selectreportbazzar3, setselectreportbazzar3] = useState<any>([])
    const [daterangevalue, setdaterangevalue] = useState<any>([]);
    const [daterangevalue1, setdaterangevalue1] = useState<any>([]);
    const [walletdatas, setwalletdatas] = useState<any>()
    const [Subscribers, setSubscribers] = useState(0)
    const [Plans, setPlans] = useState(0)
    const [mapdata, setmapdata] = useState<any>([])
    const [count, setcount] = useState<any>(1)
    const [getplandata, setplandata] = useState<any>([]);
    const [reports, setReports] = useState<any>();
    const [planSold, setPlanSold] = useState<any>();
    const [reportStateList, setReportStateList] = useState<any>([]);
    const [reportDisList, setReportDisList] = useState<any>([]);
    const [reportCityList, setReportCityList] = useState<any>([]);
    const [planStateList, setPlanStateList] = useState<any>([]);
    const [planDisList, setPlanDisList] = useState<any>([]);
    const [planCityList, setPlanCityList] = useState<any>([]);
    const [allDis, setAllDis] = useState<any>([]);
    const [AllState, setAllState] = useState<any>([]);
    const [AllCity, setAllCity] = useState<any>([]);
    const [finYears,setFinYears] = useState<any>([]);

    useEffect(() => {
        getAllDis({}, "");
        getAllState({}, "");
        getAllCity({}, "");

        setFinYears(getFinancialYears())
    }, [])

    useEffect(() => {
        const states = reportStateList.filter((item: any) => item.status === true).map((item: any) => item.value)
        const bazaars = reportBazzarlist.filter((item: any) => item.status === true).map((item: any) => item.value)
        const dists = reportDisList.filter((item: any) => item.status === true).map((item: any) => item.value)
        const cities = reportCityList.filter((item: any) => item.status === true).map((item: any) => item.value)

        let params = {};
        if (states?.length) {
            params = { ...params, state: states.join(",") }
        }
        if (dists?.length) {
            params = { ...params, district: dists.join(",") }
        }
        if (cities?.length) {
            params = { ...params, city: cities.join(",") }
        }
        if (bazaars?.length) {
            params = { ...params, bazaar: bazaars.join(",") }
        }
        if (activeTab) {
            const date = activeTab === "range" ? getFormatedDateRange(daterangevalue) : getDateRange(activeTab) as any
            params = { ...params, start_date: date?.start_date, end_date: date?.end_date }
        }
        getDashboardReports(params)
    }, [reportStateList, reportBazzarlist, reportDisList, reportCityList, activeTab])

    useEffect(() => {
        const states = planStateList.filter((item: any) => item.status === true).map((item: any) => item.value)
        const bazaars = planBazzarlist.filter((item: any) => item.status === true).map((item: any) => item.value)
        const dists = planDisList.filter((item: any) => item.status === true).map((item: any) => item.value)
        const cities = planCityList.filter((item: any) => item.status === true).map((item: any) => item.value)

        let params = {};
        if (states?.length) {
            params = { ...params, state: states.join(",") }
        }
        if (dists?.length) {
            params = { ...params, district: dists.join(",") }
        }
        if (cities?.length) {
            params = { ...params, city: cities.join(",") }
        }
        if (bazaars?.length) {
            params = { ...params, bazaar: bazaars.join(",") }
        }
        if (activeTab1) {
            const date = activeTab1 === "range" ? getFormatedDateRange(daterangevalue1) : getDateRange(activeTab1) as any
            params = { ...params, start_date: date?.start_date, end_date: date?.end_date }
        }
        getDashboardPlansSold(params)
    }, [planStateList, planBazzarlist, planDisList, planCityList, activeTab1])

    const getAllDis = async (param: any, type: any) => {
        const responseJson = await AppService.getTotalDistrict(param)
        setAllDis(responseJson.data);
    };
    const getAllState = async (param: any, type: any) => {
        const responseJson = await AppService.getTotalCity(param)
        setAllState(responseJson.data);
    };
    const getAllCity = async (param: any, type: any) => {
        const responseJson = await AppService.getAllCity(param)
        setAllCity(responseJson.data.results);
    };

    const getAllStateData = async (params: any, setState: any) => {
        try {
            const responseJson = await AppService.getAllStateByBazaar(params);

            const tempState = responseJson.data.results.flatMap((row: any) =>
                row.bazaar_state.map((id: any) => {
                    const stateItem = AllState.find((item: any) => item.id === id);
                    if (stateItem) {
                        return {
                            label: stateItem.state,
                            value: id,
                        };
                    }
                    return null; // Handle cases where a matching state is not found
                })
            );

            console.log("logger123...", AllState, responseJson.data, tempState);
            setState(tempState);
        } catch (error) {
            // Handle errors here
            console.error(error);
        }
    };

    const getAllDisData = async (params: any, setState: any) => {

        const responseJson = await AppService.getAllDistricByState(params);
        const tempState = responseJson.data.results.flatMap((row: any) =>
            row.district.map((item: any) => {
                return {
                    label: item.district,
                    value: item.id,
                };
            })
        );

        console.log("logger123...", allDis, responseJson.data, tempState);
        setState(tempState);
    };
    const getAllCityData = async (params: any, setState: any) => {
        const responseJson = await AppService.getAllCityByDis(params);
        const tempState = responseJson.data.results.flatMap((row: any) =>
            row.city.map((item: any) => {
                return {
                    label: item.city,
                    value: item.id,
                };
            })
        );
        setState(tempState);
    };

    const getDashboardReports = async (params: any = {}) => {
        try {
            const response = await AppService.getAllreportToday(params);
            console.log("Dashboard Reports...", response)
            setReports(response.data)

        } catch (err: any) {
            console.log("ERROR ====>", err.message)
        }
    }

    const getDashboardPlansSold = async (params: any = {}) => {
        try {
            const response = await AppService.getAllplanToday(params);
            console.log("Dashboard Plan Sold...", response)
            setPlanSold(response.data)

        } catch (err: any) {
            console.log("ERROR ====>", err.message)
        }
    }

    //   useEffect(()=>{
    //     getDashboardReports()
    //   },[])

    //   useEffect(()=>{
    //     getDashboardPlansSold()
    //   },[])

    const getAllLists = async () => {
        const responseJson = await AppService.getAllDashBazaarLists();

        // setTotalBazaar(responseJson.data.bazaar);
        // setWholesellers(responseJson.data.wholeseller);
        // setRevenue(responseJson.data.revenue);
        // setBill(responseJson.data.bill);
        // setAgent(responseJson.data.agent);
        // setCommission(responseJson.data.commission);
        // setCustomer(responseJson.data.customer);
    };

    const getwholesellerdata = async () => {
        const responseJson = await AppService.getAllListwholesellerNew();
        let data = await responseJson.data.results
        let localStoragedata = JSON.parse(localStorage.getItem("agency") || '').id
        data?.map((x: any) => {
            if (localStoragedata == x.wholeseller_agent) {
                mapdata.push(x)
                setmapdata([...mapdata])
                setSubscribers(x.wholeseller_agent?.length)
                setPlans(x.wholeseller_agent?.length)
                console.log(mapdata, "responseJson++")
            }
        })
    }
    const getPlans = async () => {
        const responseJson = await AppService.getPlans({ page: count });
        if (responseJson.status == 200) {
            setcount((prev: any) => prev + 1)
            setplandata((prev: any) => [...prev, ...responseJson.data.results])
        }

    }

    useEffect(() => {
        getPlans()
    }, [count])
    useEffect(() => {
        getwholesellerdata()
    }, [])


    const [value, setValue] = React.useState<Dayjs | null>(
        dayjs('2021-08-18T21:11:54'),
    );

    const handleChange = async (newValue: any) => {
        const formet: any = dayjs(newValue)
        setYearval(String(formet["$y"]))
        // const responseJson = await AppService.getAllSummerys(String(formet["$y"]))
        // console.log('responseJson', yearval)
        setValue(formet);
    };
    const getAllListsMain = async () => {
        const responseJson = await AppService.getAllBazarList(1);
        setGetAllBazar(responseJson.data.results.slice(0, 9));
    };

    const walletdata = async (id: any) => {
        const responseJson = await AppService.walletdata(id);
        setwalletdatas(responseJson.data)
        // console.log(responseJson.data, "datas")
    }

    useEffect(() => {
        getAllLists();
        getAllListsMain();
        walletdata(JSON.parse(localStorage.getItem("agency") || '')?.id)
    }, []);

    const classes = useDashboardStyles();


    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();
    const handleClick = () => navigate('/bazaars');


    const handleToggle = async (e: any) => {
        setActiveTab(e)
        setdaterangevalue([]);
    };

    const handleToggle1 = async (e: any) => {
        setActiveTab1(e)
        setdaterangevalue1([]);
    };

    const handletodayAction = async (val?: any) => {
        setActive(val)
        const responseJson = await AppService.getAllplanToday(val)
        setTodayrespo(responseJson?.data?.plan)
    }
    console.log('todayrespo', todayreport, 'todayplan', todayrespo)

    const handleRemarksStateChange = (e: any, selectboxName: string, id: string) => {
        if (selectboxName === 'State') {
            const updatedData = reportStateList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
            const ids = updatedData.filter((item: any) => item.status === true).map((item: any) => item.value).join(",");
            console.log("logger123...", updatedData, id, AllState)
            getAllDisData({ ids }, setReportDisList);
            setReportStateList(updatedData);
        }
        if (selectboxName === 'District') {

            const updatedData = reportDisList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
            const ids = updatedData.filter((item: any) => item.status === true).map((item: any) => item.value).join(",");
            getAllCityData({ ids }, setReportCityList);

            setReportDisList(updatedData);
        }
        if (selectboxName === 'City') {
            setReportCityList((prev: any) => {
                return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            });
        }
        if (selectboxName === 'Bazaar') {
            const updatedData = reportBazzarlist.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            const ids = updatedData.filter((item: any) => item.status === true).map((item: any) => item.value).join(",");
            getAllStateData({ ids }, setReportStateList);
            setReportBazzarlist(updatedData);

        }
        console.log("logger123...", selectboxName, id)
    }

    const handlePlanStateChange = (e: any, selectboxName: string, id: string) => {
        if (selectboxName === 'State') {
            const updatedData = planStateList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
            const ids = updatedData.filter((item: any) => item.status === true).map((item: any) => item.value).join(",");
            console.log("logger123...", updatedData, id, AllState)
            getAllDisData({ ids }, setPlanDisList);
            setPlanStateList(updatedData);
        }
        if (selectboxName === 'District') {

            const updatedData = planDisList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
            const ids = updatedData.filter((item: any) => item.status === true).map((item: any) => item.value).join(",");
            getAllCityData({ ids }, setPlanCityList);

            setPlanDisList(updatedData);
        }
        if (selectboxName === 'City') {
            setPlanCityList((prev: any) => {
                return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            });
        }
        if (selectboxName === 'Bazaar') {
            const updatedData = planBazzarlist.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            const ids = updatedData.filter((item: any) => item.status === true).map((item: any) => item.value).join(",");
            getAllStateData({ ids }, setPlanStateList);
            setPlanBazzarlist(updatedData);

        }
        console.log("logger123...", selectboxName, id)
    }

    const handleStateChange2 = (e: any, selectboxName: string, id: string) => {
        const { checked } = e.target;
        if (selectboxName === 'All Bazaars') {
            if (checked === true) {
                setselectreportbazzar2((prev: any) => ([...prev, id]))
            } else {
                const filterbazzar = selectreportbazzar2?.filter((im: any) => im !== id)
                setselectreportbazzar2([...filterbazzar])
            }
        }
    }

    const handleStateChange3 = (e: any, selectboxName: string, id: string) => {
        const { checked } = e.target;
        if (selectboxName === 'All Bazaars') {
            if (checked === true) {
                setselectreportbazzar3((prev: any) => ([...prev, id]))
            } else {
                const filterbazzar = selectreportbazzar3?.filter((im: any) => im !== id)
                setselectreportbazzar3([...filterbazzar])
            }
        }
        if (selectboxName === "Plan Name") {
            if (checked === true) {
                setselectplanname((prev: any) => ([...prev, id]))
            } else {
                const filterbazzar = selectplanname?.filter((im: any) => im !== id)
                setselectplanname([...filterbazzar])
            }
        }
    }

    const getAllbazzarlist = async () => {
        const responceJson = await AppService?.getAllBazaar()
        const bazaardata = responceJson?.data?.results?.map((it: any) => {
            return {
                label: it?.bazaar_name,
                value: it?.id
            }
        }
        )
        setReportBazzarlist([...bazaardata])
        setPlanBazzarlist([...bazaardata])
    }
    useEffect(() => {
        getAllbazzarlist()
    }, [])
    // const getreportbazzar = async (selectreportbazzar: any) => {
    //     const namefilter = bazzarlist?.filter((it: any) => selectreportbazzar?.includes(it?.value))?.map((im: any) => im?.label);
    //     if (namefilter?.length > 0) {
    //         const responseJson = await AppService?.getAllreportfilterbazzar(namefilter?.join(','))
    //         console.log('responseJson', responseJson?.data)
    //     }
    // }
    // const getplanbazzar = async (selectreportbazzar: any) => {
    //     const namefilter = bazzarlist?.filter((it: any) => selectreportbazzar?.includes(it?.value))?.map((im: any) => im?.label);
    //     if (namefilter?.length > 0) {
    //         const responseJson = await AppService?.getAllplanfilterbazzar(namefilter?.join(','))
    //         console.log('responseJson2', responseJson?.data)
    //     }
    // }
    // const getplanbazzar2 = async (selectreportbazzar: any) => {
    //     const namefilter = bazzarlist?.filter((it: any) => selectreportbazzar?.includes(it?.value))?.map((im: any) => im?.label);
    //     if (namefilter?.length > 0) {
    //         const responseJson = await AppService?.getAllplanfilterbazzar(namefilter?.join(','))
    //         console.log('responseJson3', responseJson?.data)
    //     }
    // }
    const getAllplannames = async () => {
        const responseJson = await AppService.getAllPlansData()
        const data = responseJson?.data?.results?.map((im: any) => {
            return {
                label: im?.plan_name,
                value: Math.floor(Math.random() * 999999)
            }
        })
        setgetPlanname([...data])
    }

    const totaleEarningCount = async (id: any) => {
        let data = await AppService.totaleEarningCount(id)
        console.log(data?.data)
        setCommission(data?.data?.TotalCommission)
    }

    const wholesellerCout = async (id: any) => {
        let data = await AppService.wholesellerCount(id)
        setWholesellers(data?.data?.total_wholeseller_count)
    }

    useEffect(() => {
        getAllplannames()
        wholesellerCout(JSON.parse(localStorage.getItem("agency") || '').id)
        totaleEarningCount(JSON.parse(localStorage.getItem("agency") || '').id)
    }, [])
    // useEffect(() => {
    //     getreportbazzar(selectreportbazzar)
    // }, [selectreportbazzar?.length])

    // useEffect(() => {
    //     getplanbazzar(selectreportbazzar2)
    // }, [selectreportbazzar2?.length])

    // useEffect(() => {
    //     getplanbazzar2(selectreportbazzar3)
    // }, [selectreportbazzar3?.length])

    useEffect(() => {
        if (daterangevalue?.length == 2) {
            // const date = daterangevalue.map((item:any)=>`${item.day}/${item.month.number}/${item.year}`).join(",")
            setActiveTab("range");
        }
    }, [daterangevalue])

    useEffect(() => {
        if (daterangevalue1?.length == 2) {
            // const date = daterangevalue1.map((item:any)=>`${item.day}/${item.month.number}/${item.year}`).join(",")
            setActiveTab1("range");
        }
    }, [daterangevalue1])

    return (
        <>
            <DashboardLayout>
                <div className={classes.root}>
                    <div className={classes.summaryTitle}>
                        <p>Summary </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                                {/* <DesktopDatePicker
                                    views={["year"]}
                                    inputFormat={yearval}
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(params) => <TextField {...params} />}
                                /> */}

                                <Select
                                    value={yearval}
                                    variant="outlined"
                                    onChange={(e: any) => setYearval(e.target.value)}
                                    sx={{ width: "140px" }}
                                >
                                    {
                                        finYears.map((item: any) => (
                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                        ))
                                    }
                                </Select>


                            </Stack>
                        </LocalizationProvider>
                    </div>

                    <div>
                        <SummaryCard year={yearval} />
                    </div>

                    <div>
                        <p className="pb-[16px] font-[600] text-[20px] leading-[25px] pt-[25px]">Wallet</p>

                        <div className="border-2 rounded-[4px] py-[20px] px-[5px] inline-block">
                            <div className="flex">
                                <div className="px-[20px] border-r-2">
                                    <span className="font-[500] text-[15px] leading-[20px] text-[#84818A] inline-block py-1">Total Earned</span>
                                    <span className="text-[28px] font-[600] leading-[36px] block text-[#5542F6]">{formatIndianRupee(walletdatas?.total_amount_earned || 0)}</span>
                                </div>
                                <div className="px-[20px] border-r-2">
                                    <span className="font-[500] text-[15px] leading-[20px] text-[#84818A] inline-block py-1">Total Withdrawn</span>
                                    <span className="text-[28px] font-[600] leading-[36px] block text-[#00A5FF]">{formatIndianRupee(walletdatas?.total_amount_withdrawn || 0)}</span>
                                </div>
                                <div className="px-[20px]">
                                    <span className="font-[500] text-[15px] leading-[20px] text-[#84818A] inline-block py-1">Remaining Balance</span>
                                    <span className="text-[28px] font-[600] leading-[36px] block text-[#20C9AC]">{formatIndianRupee(walletdatas?.agent_balance || 0)}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.commonTitle}>
                        <p>Report</p>
                    </div>

                    <div className="bazaarButtons">
                        <div onClick={() => {
                            handleToggle('today');
                        }}><ActionButton variant={activeTab === 'today' ? "primary" : "default"} title={"Today"} /></div>
                        <div onClick={() => {
                            handleToggle('this_week');
                        }}><ActionButton variant={activeTab === 'this_week' ? "primary" : "default"} title={"This Week"} />
                        </div>
                        <div onClick={() => {
                            handleToggle('last_week');
                        }}><ActionButton variant={activeTab === 'last_week' ? "primary" : "default"} title={"Last Week"} />
                        </div>
                        <div onClick={() => {
                            handleToggle('this_month');
                        }}><ActionButton variant={activeTab === 'this_month' ? "primary" : "default"} title={"This Month"} />
                        </div>
                        <div onClick={() => {
                            handleToggle('last_month');
                        }}><ActionButton variant={activeTab === 'last_month' ? "primary" : "default"} title={"Last Month"} />
                        </div>
                        <DatePicker render={<input type="text" readOnly className="h-[40px] cursor-pointer w-[194px] text-[#84818A] text-[14px] font-[Manrope] font-[600] outline-none border border-[#EBEAED] px-[5px]" placeholder="Select Date Range" />} value={daterangevalue} dateSeparator=" to " format="DD/MM/YYYY" range onChange={setdaterangevalue} />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                                <DateRangePicker
                                    label="Year"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(startProps: any, endProps: any) => (
                                        <React.Fragment>
                                            <TextField {...startProps} />
                                            <Box sx={{ mx: 2 }}> to </Box>
                                            <TextField {...endProps} />
                                        </React.Fragment>
                                    )}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </div>


                    <div className="bazaarFilters pt-[20px]">
                        <CommonSelect
                            handleSelect={handleRemarksStateChange}
                            label={"Bazaar"}
                            hint={"Select Bazaar"}
                            options={reportBazzarlist}
                            selectedIds={reportBazzarlist?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handleRemarksStateChange}
                            label={"State"}
                            hint={"Search"}
                            options={reportStateList}
                            selectedIds={reportStateList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handleRemarksStateChange}
                            label={"District"}
                            hint={"Search"}
                            options={reportDisList}
                            selectedIds={reportDisList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handleRemarksStateChange}
                            label={"City"}
                            hint={"Search"}
                            options={reportCityList}
                            selectedIds={reportCityList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />
                    </div>

                    <div className="border-2 rounded-[4px] py-[25px] px-[25px] inline-block my-[23px]">
                        <div className="flex">
                            <div className="pr-[100px] border-r-2">
                                <span className="font-[500] text-[15px] leading-[20px] text-[#84818A] inline-block py-2">Total Wholesellers</span>
                                <span className="text-[30px] font-[700] leading-[32px] block text-[#5542F6] pb-2">{reports?.wholeseller || 0}</span>
                            </div>
                            <div className="pl-[100px]">
                                <span className="font-[500] text-[15px] leading-[20px] text-[#84818A] inline-block py-2">Total Commission Earned</span>
                                <span className="text-[30px] font-[700] leading-[32px] block pb-2">{formatIndianRupee(reports?.commission || 0)}</span>
                            </div>
                        </div>
                    </div>

                    <div className={classes.commonTitle}>
                        <p>Plans Sold</p>
                    </div>

                    <div className="bazaarButtons">
                        <div onClick={() => {
                            handleToggle1('today');
                        }}><ActionButton variant={activeTab1 === 'today' ? "primary" : "default"} title={"Today"} /></div>
                        <div onClick={() => {
                            handleToggle1('this_week');
                        }}><ActionButton variant={activeTab1 === 'this_week' ? "primary" : "default"} title={"This Week"} />
                        </div>
                        <div onClick={() => {
                            handleToggle1('last_week');
                        }}><ActionButton variant={activeTab1 === 'last_week' ? "primary" : "default"} title={"Last Week"} />
                        </div>
                        <div onClick={() => {
                            handleToggle1('this_month');
                        }}><ActionButton variant={activeTab1 === 'this_month' ? "primary" : "default"} title={"This Month"} />
                        </div>
                        <div onClick={() => {
                            handleToggle1('last_month');
                        }}><ActionButton variant={activeTab1 === 'last_month' ? "primary" : "default"} title={"Last Month"} />
                        </div>
                        <DatePicker render={<input type="text" readOnly className="h-[40px] cursor-pointer w-[194px] text-[#84818A] text-[14px] font-[Manrope] font-[600] outline-none border border-[#EBEAED] px-[5px]" placeholder="Select Date Range" />} value={daterangevalue1} dateSeparator=" to " format="DD/MM/YYYY" range onChange={setdaterangevalue1} />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                                <DateRangePicker
                                    label="Year"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(startProps: any, endProps: any) => (
                                        <React.Fragment>
                                            <TextField {...startProps} />
                                            <Box sx={{ mx: 2 }}> to </Box>
                                            <TextField {...endProps} />
                                        </React.Fragment>
                                    )}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </div>


                    <div className="bazaarFilters pt-[20px]">
                        <CommonSelect
                            handleSelect={handlePlanStateChange}
                            label={"Bazaar"}
                            hint={"Select Bazaar"}
                            options={planBazzarlist}
                            selectedIds={planBazzarlist?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handlePlanStateChange}
                            label={"State"}
                            hint={"Search"}
                            options={planStateList}
                            selectedIds={planStateList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handlePlanStateChange}
                            label={"District"}
                            hint={"Search"}
                            options={planDisList}
                            selectedIds={planDisList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handlePlanStateChange}
                            label={"City"}
                            hint={"Search"}
                            options={planCityList}
                            selectedIds={planCityList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />
                    </div>

                    <div className="border-2 rounded-[4px] py-[20px] px-[5px] inline-block">
                        <div className="flex">
                            <div className="px-[25px] border-r-2">
                                <span className="font-[500] text-[15px] leading-[20px] text-[#84818A] inline-block py-2">Total Plans</span>
                                <span className="text-[28px] font-[600] leading-[36px] block text-[#5542F6]">{planSold?.plan || 0}</span>
                            </div>
                            <div className="px-[25px] border-r-2">
                                <span className="font-[500] text-[15px] leading-[20px] text-[#84818A] inline-block py-2">Total Subscribers</span>
                                <span className="text-[28px] font-[600] leading-[36px] block text-[#00A5FF]">{planSold?.subscriber || 0}</span>
                            </div>
                            <div className="px-[25px]">
                                <span className="font-[500] text-[15px] leading-[20px] text-[#84818A] inline-block py-2">Commission Earned</span>
                                <span className="text-[28px] font-[600] leading-[36px] block text-[#20C9AC]">{formatIndianRupee(planSold?.revenue || 0)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* All Plans Details */}
                <Dialog open={open} className="planPopUP" onClose={handleClose} aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div className="flex items-center justify-between">
                        <p className="text-[#2E2C34] text-[18px] font-semibold pb-3">All Plan Sold</p>
                        <p className="closeBtn cursor-pointer" onClick={handleClose}></p>
                    </div>
                    {/* <div className="bazaarButtons">
                        <ActionButton variant={active === "Today" ? "primary" : 'default'} title={"Today"} onClick={() => handletodayAction("Today")} />
                        <ActionButton variant={active === "This Week" ? "primary" : 'default'} title={"This Week"} onClick={() => handletodayAction("This Week")} />
                        <ActionButton variant={active === "Last Week" ? "primary" : 'default'} title={"Last Week"} onClick={() => handletodayAction("Last Week")} />
                        <ActionButton variant={active === "This Month" ? "primary" : 'default'} title={"This Month"} onClick={() => handletodayAction("This Month")} />
                        <ActionButton variant={active === "Last Month" ? "primary" : 'default'} title={"Last Month"} onClick={() => handletodayAction("Last Month")} />
                        <ActionButton variant={active === "Add" ? "primary" : 'default'} title={"Add"} onClick={() => handletodayAction("Add")} />
                    </div> */}

                    <div className="flex gap-2 py-[20px]">
                        <CommonSelect
                            handleSelect={handleStateChange3}
                            label={"Plan Name"}
                            hint={"Select Plan Name"}
                            options={getPlanname}
                            selectedIds={selectplanname}
                        />

                        <CommonSelect
                            handleSelect={handleStateChange3}
                            label={"All Bazaars"}
                            hint={"Select Bazaar"}
                            options={[]}
                            selectedIds={selectreportbazzar3}
                        />


                        <CommonSelect
                            handleSelect={handleStateChange3}
                            label={"All States"}
                            hint={"Search"}
                            options={[
                                {
                                    label: "Option #1",
                                    value: "Option #1"
                                },
                                {
                                    label: "Option #1",
                                    value: "Option #1"
                                },
                                {
                                    label: "Option #1",
                                    value: "Option #1"
                                },
                                {
                                    label: "Option #1",
                                    value: "Option #1"
                                },
                            ]}
                        />

                        <CommonSelect
                            handleSelect={handleStateChange2}
                            label={"All District"}
                            hint={"Search"}
                            options={[
                                {
                                    label: "Option #1",
                                    value: "Option #1"
                                },
                                {
                                    label: "Option #1",
                                    value: "Option #1"
                                },
                                {
                                    label: "Option #1",
                                    value: "Option #1"
                                },
                                {
                                    label: "Option #1",
                                    value: "Option #1"
                                },
                            ]}
                        />

                        <CommonSelect
                            handleSelect={handleStateChange2}
                            label={"All Cities"}
                            hint={"Search"}
                            options={[
                                {
                                    label: "Option #1",
                                    value: "Option #1"
                                },
                                {
                                    label: "Option #1",
                                    value: "Option #1"
                                },
                                {
                                    label: "Option #1",
                                    value: "Option #1"
                                },
                                {
                                    label: "Option #1",
                                    value: "Option #1"
                                },
                            ]}
                        />
                    </div>

                </Dialog>
                {/* All Plans Details */}
                <div>
                    {mapdata?.length ?
                        <table className="w-full">
                            <thead>
                                <tr className="border-b-[1px]">
                                    <th className="font-[500] text-[15px] leading-[20px] text-[#84818A] text-start">Plan Name</th>
                                    <th className="font-[500] text-[15px] leading-[20px] text-[#84818A]">Bazaar</th>
                                    <th className="font-[500] text-[15px] leading-[20px] text-[#84818A]">State</th>
                                    <th className="font-[500] text-[15px] leading-[20px] text-[#84818A]">District</th>
                                    <th className="font-[500] text-[15px] leading-[20px] text-[#84818A]">City</th>
                                    <th className="font-[500] text-[15px] leading-[20px] text-[#84818A]">Duration</th>
                                    <th className="font-[500] text-[15px] leading-[20px] text-[#84818A]">Plan Price</th>
                                    <th className="text-center w-[270px] font-[500] text-[15px] leading-[20px] text-[#84818A]">
                                        Subscribers
                                        <div className="flex justify-center gap-6">
                                            <th className="font-[500] text-[14px] leading-[20px] text-[#84818A]">Active</th>
                                            <th className="font-[500] text-[14px] leading-[20px] text-[#84818A]">Expired</th>
                                            <th className="font-[500] text-[14px] leading-[20px] text-[#84818A]">Deactivated</th>
                                        </div>
                                    </th>
                                    <th className="font-[500] text-[15px] leading-[20px] text-[#84818A] w-[250px] ">Commission Earned</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mapdata.map((data: any) => (
                                    <tr className="border-b-[1px]">
                                        <td className="font-[500] text-[14px] leading-[20px] w-[140px] py-8">{getplandata?.map((x: any) => data?.wholeseller_plan == x.id ? x?.plan_name : '')}</td>
                                        <td className="font-[500] text-[14px] leading-[20px] w-[180px]">{data?.wholeseller_bazaar_data[0]?.bazaar_name}</td>
                                        <td className="font-[500] text-[14px] leading-[20px] w-[140px] text-center">{data?.wholeseller_state_name}</td>
                                        <td className="font-[500] text-[14px] leading-[20px] w-[140px] text-center">{data?.wholeseller_district_name}</td>
                                        <td className="font-[500] text-[14px] leading-[20px] w-[140px] text-center">{data?.wholeseller_city_name}</td>
                                        <td className="font-[500] text-[14px] leading-[20px] w-[140px] text-center">{getplandata?.map((x: any) => data?.wholeseller_plan == x.id ? x?.plan_periods_in_days : '')}</td>
                                        <td className="font-[500] text-[14px] leading-[20px] w-[140px] text-center">{getplandata?.map((x: any) => data?.wholeseller_plan == x.id ? x?.amount : '')}</td>
                                        <td>
                                            <div className="flex justify-center gap-10">
                                                <td className="text-[14px]">{data.bazaar_active ? "1" : ''}</td>
                                                <td className="text-[14px]">{!data.bazaar_active ? "1" : ''}</td>
                                                <td className="text-[14px]"></td>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center">
                                                <div>
                                                    <span className="font-[500] text-[14px] leading-[20px]">{data?.earnings ? data?.earnings : ''}</span>
                                                    <span className="font-[500] text-[14px] leading-[20px] text-[#84818A] ps-[5px]">16.30%</span>
                                                    <Box sx={{ width: '70%' }}>
                                                        <LinearProgress variant="determinate" value={18} />
                                                    </Box>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> : ''
                    }

                </div>

            </DashboardLayout>
        </>
    );
};

export default Dashboard;
