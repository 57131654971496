import { MenuItem } from "@/components/organisms/SidebarPanel/MenuItem";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import { routePaths } from "@/routes";
import { SidebarPanel } from "@/components/organisms/SidebarPanel";
import BadgeIcon from '@mui/icons-material/Badge';

const AdminSidebar = (props: any) => {
  return (
    <>
      <SidebarPanel>
        <MenuItem
          href={routePaths.dashboard}
          icon={<DashboardIcon />}
          label={"Dashboard"}
        />
        <MenuItem href={"/ourAgents"} icon={<BadgeIcon />} label={"Our Agents"} />
      </SidebarPanel>
    </>
  );
};

export { AdminSidebar };
