import Dashboard from "@/screens/Dashboard";
import Login from "@/screens/Login";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { routePaths } from "./routePaths";
import OurAgents from "@/screens/OurAgents/OurAgents";
import AddOurAgents from "@/screens/AddourAgents/AddOurAgents";
import AgentDetails from "@/screens/AgentDetails";
import { useEffect, useState } from "react";
import { useAuth } from "@/context/authContext";

const AppRoutes = (props: any) => {

  const { isAuthenticated, checkLogin } = useAuth();

  return (
    <>
      <Router>
        <Routes>
          {
            !isAuthenticated ? (
              <>
                <Route path={routePaths.root} element={<Login />} />
                <Route path={routePaths.login} element={<Login />} />
                <Route path="*" element={<Navigate
                    to='/login'
                  />}/>
              </>
            ) :
              (
                <>
                  <Route path={routePaths.dashboard} element={<Dashboard />} />
                  <Route path={routePaths.ourAgents} element={<OurAgents />} />
                  <Route path={routePaths.addourAgents} element={<AddOurAgents />} />
                  <Route path={routePaths.editourAgents} element={<AddOurAgents />} />
                  <Route path={routePaths.AgentDetails} element={<AgentDetails />} />
                  <Route path="*" element={<Navigate
                    to={routePaths.dashboard}
                  />}/>
                </>
              )
          }

        </Routes>
      </Router>
    </>
  );
};

export { AppRoutes as Routes };
