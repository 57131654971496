// import React, { useEffect, useState } from "react";
// import CommonSelect from "@/components/atoms/CommonSelect/CommonSelect";
// import { GridOptionButton } from "@/components/atoms/Button";
// import complete from "@/static/icons/complete.svg";
// import fill from "@/static/icons/fill.svg";
// import deleteagent from "@/static/icons/delete-agent.svg";
// import calendar from "@/static/icons/calendar.svg";
// import { AppService } from "@/service/AllApiData.service";
// import moment from "moment";
// import contact from "@/static/icons/contact-phone.svg";
// import { useNavigate } from "react-router-dom";
// import { Alert } from "@/alert/Alert";
// import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
// import { useAgentDetailsStyle } from "@/static/stylesheets/molecules/agentDetailsStyle";

// interface AgentWholesalerType {
//     data?: any;
//     id?: any;
// }
// const AgentWholesaler: React.FC<AgentWholesalerType> = ({ data }) => {
//     const classes = useAgentDetailsStyle();
//     const navigate = useNavigate()
//     const [AllWholesaler, setAllWholesaler] = useState<any>();
//     const [AllWholesalerM, setAllWholesalerM] = useState<any>();
//     const [filterAllwholesellers, setfilterAllwholesellers] = useState<any>();
//     const [allDis, setAllDis] = useState<any>([]);
//     const [AllState, setAllState] = useState<any>([]);
//     const [AllCity, setAllCity] = useState<any>([]);
//     const [AllBazaarData, setAllBazaarData] = useState<any>([]);
//     const [bazaarList, setBazaarList] = useState([])
//     const [stateList, setstateList] = useState<any[]>([])
//     const [disList, setdisList] = useState<any[]>([])
//     const [cityList, setcityList] = useState<any[]>([])
//     const [AllAgentType, setAllAgentType] = useState<any>([]);
//     const [agentTypeList, setagentTypeList] = useState<any>([]);
//     const [AllActiveInactive, setAllActiveInactive] = useState<any>([]);
//     const [activeInactiveList, setactiveInactiveList] = useState<any>([]);
//     const [Allstatus, setAllstatus] = useState<any>([]);
//     const [statusList, setstatusList] = useState<any>([]);
//     const [wholesellerTypeList,setWholeSellerTyeList] = useState<any[]>([]);
//     const [planStatusList,setPlanStatusList] = useState<any[]>([]);
    
//     const agent = data?.agent_name
//     useEffect(() => {
//         getAllLists();
//     }, [data]);

//     console.log(data)

//     const getWholeSellerTypeList = async () => {
//         try{
//             const response = await AppService.getWholesellerType({});
//             const tempState = response.data.results.map((item:any)=>({
//                 label: item.wholeseller_type_name,
//                 value: item.id
//             }))
//             setWholeSellerTyeList(tempState);
//         } catch (err:any) {
//             console.log("GET WHOLESELLER TYPE ERROR",err?.message)
//         }
//     }

//     const getAllLists = async () => {
//         const responseJson = await AppService.listwholesalersearch();
//         if (responseJson.data.results?.length > 0) {
//             let AllWholeSalerlist = responseJson.data.results?.map((item: any) => {
//                 if (item.wholeseller_agent === data.id) {
//                     return { ...item, agent }
//                 }
//             })
//             const filteredWholesalers = AllWholeSalerlist.filter((item: any) => item !== undefined);
//             setAllWholesaler(filteredWholesalers)
//             setfilterAllwholesellers(filteredWholesalers)
//             setAllWholesalerM(filteredWholesalers)
//         }
//         console.log(AllWholesaler, "AllWholesaler")
//     };


//     const handleDeleteFirm = async (index: number) => {
//         if (window.confirm('Do You want to delete Wholeseller')) {
//             let deleteuserid = AllWholesaler[index].id;
//             const responseJson = await AppService.deleteWholeseller(deleteuserid);
//             if (responseJson.status == 204) {
//                 Alert('Wholeseller Delete Successfully');
//                 getAllLists();
//             }
//         }
//     }

//     useEffect(() => {
//         getAllAgentTypes()
//         getAllAgentTypeData()
//         getWholeSellerTypeList()
//     }, [])
//     useEffect(() => {
//         filterAllwholesellers?.map((item: any) => {
//             getAllDis({ ids: item?.district }, "")
//             getAllState({ ids: item?.state }, "")
//             getAllCity({ ids: item?.city }, "")
//             getAllBazaarData({ ids: item?.bazaar }, "")
//         });
//         getAllBazaar()
//         getAllStateData()
//         // getAllDisData()
//         // getAllCityData()
//         getAllAgentTypes()
//         getAllAgentTypeData()
//         getAllActiveInactive()
//         getAllActiveInactiveData()
//         getAllStatus()
//         // getAllStatusData()
//     }, [filterAllwholesellers])

//     const getAllDis = async (param: any, type: any) => {
//         const responseJson = await AppService.getTotalDistrict(param)
//         setAllDis(responseJson.data);
//     };
//     const getAllState = async (param: any, type: any) => {
//         const responseJson = await AppService.getTotalCity(param)
//         setAllState(responseJson.data);
//     };
//     const getAllCity = async (param: any, type: any) => {
//         const responseJson = await AppService.getAllCity(param)
//         setAllCity(responseJson.data.results);
//     };
//     const getAllBazaarData = async (param: any, type: any) => {
//         const responseJson = await AppService.getAllBazaar(param)
//         setAllBazaarData(responseJson.data.results);
//     };
//     const getAllBazaar = async () => {
//         const responseJson = await AppService.getAllBazaar();
//         let tempBazaar = await responseJson.data.results.map((row: any) => {
//             return {
//                 label: row.bazaar_name,
//                 value: row.id,
//             }
//         })
//         setBazaarList(tempBazaar);
//     };
//     const getAllStateData = async () => {
//         const responseJson = await AppService.getTotalCity();
//         let tempState = await responseJson.data.map((row: any) => {
//             return {
//                 label: row.state,
//                 value: row.id,
//             }
//         })
//         setstateList(tempState);
//     };
//     const getAllDisData = async (params:any) => {
//         setdisList([]);
//         setcityList([]);
//         const responseJson = await AppService.getAllDistricByState(params);
//         const tempState = responseJson.data.results.flatMap((row: any) =>
//         row.district.map((item: any) => {
//             return {
//               label: item.district,
//               value: item.id,
//             };
//         })
//       );
    
//       console.log("logger123...", allDis, responseJson.data, tempState);
//         setdisList(tempState);
//       };
//       const getAllCityData = async (params:any) => {
//         const responseJson = await AppService.getAllCityByDis(params);
//         const tempState = responseJson.data.results.flatMap((row: any) =>
//         row.city.map((item: any) => {
//             return {
//               label: item.city,
//               value: item.id,
//             };
//         })
//       );
//         setcityList(tempState);
//       };
//     const getAllAgentTypes = async () => {
//         const arr = AllWholesaler?.map((item: any) => item?.wholeseller_type_name);
//         var uniqueArray = Array.from(new Set(arr));
//         setAllAgentType(uniqueArray);
//     }
//     const getAllAgentTypeData = async () => {
//         if (AllAgentType.length > 0) {
//             let tempState = AllAgentType.map((row: any) => {
//                 return {
//                     label: row,
//                     value: row,
//                 }
//             })
//             setagentTypeList(tempState);
//         }
//     };
//     const getAllActiveInactive = async () => {
//         const arr = AllWholesaler?.map((item: any) => {
//             if (item.wholeseller_active === true) {
//                 return true
//             } else {
//                 return false
//             }
//         });
//         var uniqueArray = Array.from(new Set(arr));
//         setAllActiveInactive(uniqueArray);
//     }
//     const getAllActiveInactiveData = async () => {
//         if (AllActiveInactive.length > 0) {
//             let tempState = AllActiveInactive.map((row: any) => {
//                 return {
//                     label: row === true ? "Enable" : "Disable",
//                     value: row === true ? true : false,
//                 }
//             })
//             setactiveInactiveList(tempState);
//         }
//     };
//     const getAllStatus = async () => {
//         const arr = AllWholesaler?.map((item: any) => item.wholeseller_status);
//         const arr2 = AllWholesaler?.map((item: any) => item.wholeseller_plan_name);

//         var uniqueArray = Array.from(new Set(arr));
//         var uniqueArray2 = Array.from(new Set(arr2));

//         getAllStatusData(uniqueArray);
//         getAllPlanStatus(uniqueArray2)
//     }
//     const getAllStatusData = async (uniqueArray:any[]) => {
//         if (uniqueArray.length > 0) {
//             let tempState = uniqueArray.map((row: any) => {
//                 return {
//                     label: row,
//                     value: row,
//                 }
//             })
//             setstatusList(tempState);
//         }
//     };

//     const getAllPlanStatus = async (uniqueArray:any[]) => {
//         if (uniqueArray.length > 0) {
//             let tempState = uniqueArray.map((row: any) => {
//                 return {
//                     label: row,
//                     value: row,
//                 }
//             })
//             setPlanStatusList(tempState);
//         }
//     };

//     const handleChange = (selectboxName: string, id: any) => {
//         if (selectboxName === 'State') {
//             const updatedData = stateList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
//             const ids = updatedData.filter((item: any) => item.status === true).map((item: any) => item.value).join(",");
//             console.log("logger123...", updatedData, id, AllState)
//             getAllDisData({ ids });
//             setstateList(updatedData);
//         }
//         if (selectboxName === 'District') {
//             const updatedData = disList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
//             const ids = updatedData.filter((item: any) => item.status === true).map((item: any) => item.value).join(",");
//             console.log("logger123...", updatedData, id, AllState)
//             getAllCityData({ ids });
//             setdisList(updatedData);
//         }
//         if (selectboxName === 'City') {
//             const updatedData = cityList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
            
//             setcityList(updatedData);
//         }
//         if (selectboxName === 'Wholeseller Type') {
//             const updatedData = wholesellerTypeList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
            
//             setWholeSellerTyeList(updatedData);
//         }
//         if (selectboxName === 'Agent Type') {
//             const updatedAgentType = agentTypeList?.map((item: any) => ({
//                 ...item,
//                 status: item.value === id ? true : false
//             }));
//             setagentTypeList(updatedAgentType);
//         }
//         if (selectboxName === 'Status') {
//             const updatedStatus = statusList.map((item: any) => ({
//                 ...item,
//                 status: item.value === id ? true : false
//             }));
//             setstatusList(updatedStatus);
//         }
//         if (selectboxName === 'Plan Status') {
//             const updatedData = planStatusList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
//             setPlanStatusList(updatedData);
//         }
//     }

//     useEffect(() => {
//         let temp1 = AllWholesalerM;
//         // city
//         if (temp1?.length && cityList?.length) {
//             let FCity = cityList?.filter((b: any) => b.status === true)?.map((item: any) => item?.id)
//             if (FCity?.length > 0) {
//                 for (const x of FCity) {
//                     temp1 = temp1.filter((fc: any) => fc.wholeseller_city === x)
//                 }
//             }
//         }
//         // state
//         if (temp1?.length && stateList?.length) {
//             let FState = stateList?.filter((b: any) => b.status === true)?.map((item: any) => item?.id)
//             if (FState?.length > 0) {
//                 for (const x of FState) {
//                     temp1 = temp1?.filter((fs: any) => fs?.wholeseller_state === x)
//                 }
//             }
//         }
//         // district
//         if (temp1?.length && disList?.length) {
//             let FDistrict = disList?.filter((b: any) => b.status === true)?.map((item: any) => item?.id)
//             if (FDistrict?.length > 0) {
//                 for (const x of FDistrict) {
//                     temp1 = temp1?.filter((fs: any) => fs?.wholeseller_district === x)
//                 }
//             }
//         }
//         // wholeseller type
//         if (temp1?.length && wholesellerTypeList?.length) {
//             const selectedAgentType = wholesellerTypeList?.filter((item: any) => item.status === true).map((item: any) => item.value);
//             if (selectedAgentType?.length > 0) {
//                 temp1 = temp1?.filter((item: any) => selectedAgentType?.includes(item?.wholeseller_type));
//             }
//         }
//         // wholeseller status
//         if (temp1?.length && statusList?.length) {
//             const selectedStatus = statusList?.filter((item: any) => item.status === true).map((item: any) => item.value);
//             if (selectedStatus?.length > 0) {
//                 temp1 = temp1?.filter((item: any) => selectedStatus?.includes(item?.wholeseller_status));
//             }
//         }
//         // active / inactive
//         if (temp1?.length && planStatusList?.length) {
//             const selectedActiveInactive = planStatusList?.filter((item: any) => item.status === true).map((item: any) => item.value);
//             if (selectedActiveInactive?.length > 0) {
//                 temp1 = temp1?.filter((item: any) => selectedActiveInactive?.includes(item?.wholeseller_plan_name));
//             }
//         }
//         setAllWholesaler(temp1)
//     }, [stateList,disList,cityList,wholesellerTypeList,stateList,planStatusList])

//     return (
//         <>
//             <div className={classes.root}>
//                 <div>
//                     <div className="flex gap-5 pt-[30px] " style={{ flexWrap: "wrap" }}>
//                         <CommonSelectElectronicBazaar
//                             label={"State"}
//                             hint={"Select State"}
//                             options={stateList}
//                             handleSelect={handleChange}
//                             selectedIds={stateList.filter((item: any) => item?.status).map((elm: any) => elm.value)}
//                         />
//                         <CommonSelectElectronicBazaar
//                             label={"District"}
//                             hint={"Select District"}
//                             options={disList}
//                             handleSelect={handleChange}
//                             selectedIds={disList.filter((item: any) => item?.status).map((elm: any) => elm.value)}
//                         />
//                         <CommonSelectElectronicBazaar
//                             label={"City"}
//                             hint={"Select City"}
//                             options={cityList}
//                             handleSelect={handleChange}
//                             selectedIds={cityList.filter((item: any) => item?.status).map((elm: any) => elm.value)}
//                         />
//                         <CommonSelectElectronicBazaar
//                             label={"Wholeseller Type"}
//                             hint={"Select Wholeseller Type"}
//                             options={wholesellerTypeList}
//                             handleSelect={handleChange}
//                             selectedIds={wholesellerTypeList.filter((item: any) => item?.status).map((elm: any) => elm.value)}
//                         />
//                         <CommonSelectElectronicBazaar
//                             label={"Status"}
//                             hint={"Select Status"}
//                             options={statusList}
//                             handleSelect={handleChange}
//                             selectedIds={statusList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
//                         />
//                         {/* <CommonSelectElectronicBazaar
//                             label={"Active/Inactive"}
//                             hint={"Select Active/Inactive"}
//                             options={activeInactiveList}
//                             handleSelect={handleChange}
//                             selectedIds={activeInactiveList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
//                         />
//                         <CommonSelectElectronicBazaar
//                             label={"Agent Type"}
//                             hint={"Select Agent Type"}
//                             options={agentTypeList}
//                             handleSelect={handleChange}
//                             selectedIds={agentTypeList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
//                         /> */}
//                         <CommonSelectElectronicBazaar
//                             label={"Plan Status"}
//                             hint={"Select Plan Status"}
//                             options={planStatusList}
//                             handleSelect={handleChange}
//                             selectedIds={planStatusList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
//                         />
//                     </div>

//                     <div>
//                         <div className="relative pt-[40px] pb-[40px]" style={{ overflowX: "auto" }}>
//                             <table className="w-full text-left " style={{ marginBottom: "170px" }}>
//                                 <thead className="" style={{ borderBottom: "1px solid #e1e1e1" }}>
//                                     <tr className="color-[#2E2C34;]">
//                                         <th scope="col" className="tableTitle py-3 px-6" style={{ paddingLeft: 0 }}>
//                                             S.No
//                                         </th>
//                                         <th scope="col" className="tableTitle py-3 px-6">
//                                             Firm Name
//                                         </th>
//                                         <th scope="col" className="tableTitle py-3 px-6">
//                                             C_Name
//                                         </th>
//                                         <th scope="col" className="tableTitle py-3 px-6">
//                                             Mobile No.
//                                         </th>
//                                         <th scope="col" className="tableTitle py-3 px-6">
//                                             City
//                                         </th>
//                                         <th scope="col" className="tableTitle py-3 px-6">
//                                             Customer Type
//                                         </th>
//                                         <th scope="col" className="tableTitle py-3 px-6">
//                                             Categories
//                                         </th>
//                                         <th scope="col" className="tableTitle py-3 px-6">Status</th>
//                                         <th scope="col" className="tableTitle py-3 px-6">Plan</th>
//                                         <th scope="col" className="tableTitle py-3 px-6">Expire In</th>
//                                         <th scope="col" className="tableTitle py-3 px-6" style={{ paddingRight: 0 }}>Action</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {AllWholesaler?.length > 0 ? AllWholesaler?.map((elm: any, index: any) => {
//                                         return <tr className="border-b" key={elm.id}>
//                                             <td scope="row" className="py-4 px-6 tableData" style={{ paddingLeft: 0 }}>
//                                                 <p className="tableData">
//                                                     {index + 1}
//                                                 </p>
//                                             </td>
//                                             <td className="py-4 px-6 tableData">
//                                                 <div className="flex gap-2">
//                                                     {elm?.wholeseller_firm_name}
//                                                 </div>
//                                             </td>
//                                             <td className="py-4 px-6 tableData person-details">
//                                                 <div className="flex items-center gap-2 text-[#4E2FA9]">
//                                                     <img className="brandLogo" src={contact} alt={"Logo"} />
//                                                     {elm?.agent}
//                                                 </div>
//                                                 <div className="flex flex-col items-start gap-2 person-model">
//                                                     <span className="job-title">Firm Manager</span>
//                                                     {/* <span className="text-sm text-[#2E2C34] font-medium">
//                                                         {elm?.name}
//                                                     </span> */}
//                                                     <span className="text-[13px] text-[#84818A] font-medium leading-5">
//                                                         {elm?.wholeseller_city_name}
//                                                     </span>
//                                                     <span className="flex items-center gap-2 text-sm font-medium">
//                                                         {elm?.agent}
//                                                     </span>
//                                                 </div>
//                                             </td>
//                                             <td className="py-4 px-6 tableData">
//                                                 {elm?.wholeseller_number}
//                                             </td>
//                                             <td className="py-4 px-6 tableData">{elm?.wholeseller_city_name}</td>
//                                             <td className="py-4 px-6 tableData">
//                                                 {elm?.wholeseller_bazaar_data?.map((items: any, index: any) => {
//                                                     const lastItemIndex = elm?.wholeseller_bazaar_data.length - 1;
//                                                     return <span key={items.id} className="font-[500] text-[#2E2C34] text-[14px] font-[Manrope]">{items?.bazaar_name}{index !== lastItemIndex && ', '}</span>
//                                                 })}
//                                             </td>
//                                             <td className="py-4 px-6 tableData cursor-pointer">
//                                                 <div>
//                                                     {elm?.wholeseller_bazaar_data && elm?.wholeseller_bazaar_data[0]?.category_group?.map((element: any, index: any) => {
//                                                         const lastItemIndex = elm?.wholeseller_bazaar_data && elm?.wholeseller_bazaar_data[0]?.category_group?.length - 1;
//                                                         return <span key={element.id} className="font-[500] text-[#2E2C34] text-[14px] font-[Manrope]">{element?.parent_category_name}{index !== lastItemIndex && ', '}</span>
//                                                     })}
//                                                 </div>
//                                             </td>
//                                             <td className="py-4 px-6">
//                                                 {elm?.wholeseller_status == 'CREATED' && <div className="bg-[#e5f5ff] flex justify-center rounded-md p-[10px] w-[150px]">
//                                                     <p className="text-[#28a1ff]" style={{ fontSize: "12px", lineHeight: "18px" }}>{elm?.wholeseller_status}</p>
//                                                 </div>}
//                                                 {elm?.wholeseller_status == 'PENDING' && <div className="bg-[#FFF6ED] flex justify-center rounded-md p-[10px] w-[150px]">
//                                                     <p className="text-[#FFA043]" style={{ fontSize: "12px", lineHeight: "18px" }}>{elm?.wholeseller_status}</p>
//                                                 </div>}
//                                                 {elm?.wholeseller_status == 'KYCAPPROVED' && <div className="bg-[#e6fcf7] flex justify-center rounded-md p-[10px] w-[150px]">
//                                                     <p className="text-[#00e0c0]" style={{ fontSize: "12px", lineHeight: "18px" }}>{elm?.wholeseller_status}</p>
//                                                 </div>}
//                                                 {elm?.wholeseller_status == 'KYCREJECTED' && <div className="bg-[#ffeae5] flex justify-center rounded-md p-[10px] w-[150px]">
//                                                     <p className="text-[#ff0000]" style={{ fontSize: "12px", lineHeight: "18px" }}>{elm?.wholeseller_status}</p>
//                                                 </div>}
//                                                 {elm?.wholeseller_status == 'APPROVED' && <div className="bg-[#FFF6ED] flex justify-center rounded-md p-[10px] w-[150px]">
//                                                     <p className="text-[#FFA043]" style={{ fontSize: "12px", lineHeight: "18px" }}>{elm?.wholeseller_status}</p>
//                                                 </div>}
//                                             </td>
//                                             <td className="py-4 px-6 tableData">
//                                                 {elm?.wholeseller_plan_name}
//                                             </td>
//                                             <td className="py-4 px-6 tableData cursor-pointer">
//                                                 <div className="text-[#FFA043] text-sm font-medium">
//                                                     {moment(elm?.created_at).format("DD MMM, YYYY")}
//                                                 </div>
//                                             </td>
//                                             <td className="py-4 px-6 tableData cursor-pointer" style={{ paddingRight: 0 }}>
//                                                 <GridOptionButton
//                                                     icon={"vertical-options"}
//                                                     menus={[
//                                                         {
//                                                             label: (
//                                                                 <>
//                                                                     <span className="icon">
//                                                                         <img src={complete} alt="dots" />
//                                                                     </span>{" "}
//                                                                     Complete KYC
//                                                                 </>
//                                                             ),
//                                                             onClick() {
//                                                                 navigate(`/wholesellerkyc/${elm.id}/KYC`)
//                                                             },
//                                                         },
//                                                         {
//                                                             label: (
//                                                                 <>
//                                                                     <span className="icon">
//                                                                         <img src={fill} alt="fill" />{" "}
//                                                                     </span>{" "}
//                                                                     Edit Firm
//                                                                 </>
//                                                             ),
//                                                             onClick() {
//                                                                 navigate(`/wholesellerkyc/${elm.id}/EDIT`)
//                                                             },
//                                                         },
//                                                         {
//                                                             label: (
//                                                                 <>
//                                                                     <span className="icon">
//                                                                         <img src={deleteagent} alt="deleteagent" />{" "}
//                                                                     </span>
//                                                                     Delete Firm
//                                                                 </>
//                                                             ),
//                                                             onClick() {
//                                                                 handleDeleteFirm(index)
//                                                             }
//                                                         },
//                                                         {
//                                                             label: (
//                                                                 <>
//                                                                     <span className="icon">
//                                                                         <img src={calendar} alt="calendar" />{" "}
//                                                                     </span>{" "}
//                                                                     Renew Plan
//                                                                 </>
//                                                             ),
//                                                             onClick() {
//                                                                 navigate(`/renewplan/${elm.id}`)
//                                                             },
//                                                         },
//                                                     ]}
//                                                 />
//                                             </td>
//                                         </tr>
//                                     }) : <p className="font-[500] text-[#2E2C34] text-[14px] font-[Manrope]" style={{ paddingTop: "10px" }}>No Wholesalers yet</p>}
//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default AgentWholesaler;




import React, { useState, useEffect } from "react";
// import { DashboardLayout } from "@/components/layouts";
// import { SectionHeader } from "@/components/molecules/Bazaars";
import { usewholesellerListStyles } from "@/static/stylesheets/molecules/wholesellerListStyle";
import { AddButton } from "@/components/atoms/Button";
import { AppService } from "@/service/AllApiData.service";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import { Checkbox, Dialog, ListItemText, MenuItem, Select, TextField } from "@mui/material";
// import { Switch } from "@/components/atoms/Switch";
import NidFront from "@/static/images/mwb_nid_frnt.png";
import NidBack from "@/static/images/mwb_nid_back.png";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import complete from "@/static/icons/complete.svg";
import fill from "@/static/icons/fill.svg";
import phone from "@/static/icons/phone.svg";
import contact from "@/static/icons/contact-phone.svg";
import calendar from "@/static/icons/calendar.svg";
import deleteagent from "@/static/icons/delete-agent.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { GridOptionButton } from "@/components/atoms/Button";
import { Alert } from "@/alert/Alert";
import { Grid, Pagination } from "@mui/material";
import moment from "moment";
import { FaMapMarkerAlt } from "react-icons/fa";
import { HiPhone } from "react-icons/hi";
import Pinimage from "@/static/images/Vector (9).png";
import closeicon from "@/static/images/Vector (10).png"
import Loading from "@/components/loading";
import { Switch } from "@/components/atoms/Switch";
import Close from "@mui/icons-material/Close";
// import { useAgentDetailsStyle } from "@/static/stylesheets/molecules/agentDetailsStyle";

interface AgentWholesalerType {
    data?: any;
    id?: any;
}
const AgentWholesaler: React.FC<AgentWholesalerType> = ({ data, id }) => {
    const classes = usewholesellerListStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [getAllWholeseller, setGetAllWholeseller] = useState([]);
    const [totalCount, SetTotalCount] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [getAllWholesellers, setGetAllWholesellers] = useState<any>([]);
    const [filterAllwholeseller, setfilterAllwholeseller] = useState<any>([]);
    const [filterAllwholesellerM, setfilterAllwholesellerM] = useState<any>([]);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [iDS] = useState(localStorage.getItem("IDS"));
    const [Allagents, setAllagents] = useState<any>([]);
    const [allDis, setAllDis] = useState<any>([]);
    const [AllState, setAllState] = useState<any>([]);
    const [AllCity, setAllCity] = useState<any>([]);
    const [AllBazaarData, setAllBazaarData] = useState<any>([]);
    const [bazaarList, setBazaarList] = useState([])
    const [stateList, setstateList] = useState([])
    const [disList, setdisList] = useState([])
    const [cityList, setcityList] = useState([])
    const [AllAgentType, setAllAgentType] = useState<any>([]);
    const [agentTypeList, setagentTypeList] = useState<any>([
        {
            label: "INDIVIDUAL",
            value: "INDIVIDUAL"
        },
        {
            label: "AGENCY",
            value: "AGENCY"
        },
        {
            label: "SALESMAN",
            value: "SALESMAN"
        }
    ]);
    const [AllActiveInactive, setAllActiveInactive] = useState<any>([]);
    const [activeInactiveList, setactiveInactiveList] = useState<any>([
        {
            label: "ENABLE",
            value: 1
        },
        {
            label: "DISABLE",
            value: 0
        }
    ]);
    const [Allstatus, setAllstatus] = useState<any>([]);
    const [statusList, setstatusList] = useState<any>([
        {
            label: "CREATED",
            value: "CREATED"
        },
        {
            label: "PENDING",
            value: "PENDING"
        },
        {
            label: "KYCREJECTED",
            value: "KYCREJECTED"
        },
        {
            label: "KYCAPPROVED",
            value: "KYCAPPROVED"
        }
    ]);
    const [wholesellerTypedata, setwholesellerTypedata] = useState<any>([]);
    const [Wholesellerlist, setWholesellerlist] = useState<any>([]);
    const [Wholesalerbranch, setWholesalerbranch] = useState<any>([]);
    const [editFormData, setEditFormData] = useState<any>();
    console.log('editFormData', editFormData)
    const [searchK, setSearchK] = useState("")
    const [loading, setloading] = useState(true)


    useEffect(() => {
        getAgentAllLists()
        getWholesalerBranchAPI()
    }, [])

    // useEffect(() => {
    //   setTimeout(() => {
    //     handlePageChange("e", currentPage)
    //   }, 4000);
    // }, [])





    const getWholesellers = (loader = true, page = currentPage) => {
        const stateIds = AllState.filter((item: any) => item.status === true).map((item: any) => item.id);
        const districtIds = allDis.filter((item: any) => item.status === true).map((item: any) => item.id);
        const cityIds = AllCity.filter((item: any) => item.status === true).map((item: any) => item.id);
        const statusIds = statusList.filter((item: any) => item.status === true).map((item: any) => item.value);
        const activeIds = activeInactiveList.filter((item: any) => item.status === true).map((item: any) => item.value);
        const agentTypeIds = agentTypeList.filter((item: any) => item.status === true).map((item: any) => item.value);
        const wholeSalerTypeIds = Wholesellerlist.filter((item: any) => item.status === true).map((item: any) => item.value);


        let params = {
            page: page
        } as any
        if (wholeSalerTypeIds.length) {
            params = {
                ...params,
                wholeseller_type: wholeSalerTypeIds.join(",")
            }
        }

        if (agentTypeIds.length) {
            params = {
                ...params,
                wholeseller_agent_type: agentTypeIds.join(",")
            }
        }

        if (stateIds.length) {
            params = {
                ...params,
                wholeseller_state: stateIds.join(",")
            }
        }

        if (districtIds.length) {
            params = {
                ...params,
                wholeseller_district: districtIds.join(",")
            }
        }

        if (cityIds.length) {
            params = {
                ...params,
                wholeseller_city: cityIds.join(",")
            }
        }

        if (statusIds.length) {
            params = {
                ...params,
                wholeseller_status: statusIds.join(",")
            }
        }

        if (activeIds.length === 1) {
            params = {
                ...params,
                wholeseller_active: activeIds.join(",")
            }
        }

        if (searchK) {
            params = {
                ...params,
                search: searchK
            }
        }

        getAllLists(params, loader);

    }


    useEffect(() => {

        const stateIds = AllState.filter((item: any) => item.status === true).map((item: any) => item.id);
        const districtIds = allDis.filter((item: any) => item.status === true).map((item: any) => item.id);
        const cityIds = AllCity.filter((item: any) => item.status === true).map((item: any) => item.id);
        const statusIds = statusList.filter((item: any) => item.status === true).map((item: any) => item.value);
        const activeIds = activeInactiveList.filter((item: any) => item.status === true).map((item: any) => item.value);
        const agentTypeIds = agentTypeList.filter((item: any) => item.status === true).map((item: any) => item.value);
        const wholeSalerTypeIds = Wholesellerlist.filter((item: any) => item.status === true).map((item: any) => item.value);

        if (AllState.length) {
            setCurrentPage(1);
            getWholesellers(true, 1);
        }
    }, [AllState, allDis, AllCity, Wholesellerlist, statusList, activeInactiveList, agentTypeList])


    const handlePageChange = (event: any, value: any) => {

        setCurrentPage(value);
    };

    useEffect(() => {
        getWholesellers(false);
    }, [currentPage])

    useEffect(() => {
        setCurrentPage(1);
        getWholesellers(false, 1)
    }, [searchK])

    const getAllLists = async (params: any, loader = true) => {
        if (loader) {
            setloading(true)
        }
        params = {
            ...params,
            wholeseller_agent: id
        }
        try {
            const responseJson = await AppService.getAllWholesellerListM(params);
            if (responseJson.data.results) {
                setloading(false)
            }
            setGetAllWholesellers(responseJson.data.results);
            SetTotalCount(responseJson.data.count)
            console.log("all wholesellers list===>", responseJson);
        } catch (err: any) {

        }
    };









    // const getAllLists = async () => {
    //   const responseJson = await AppService.getAllwholesellerList();
    //   setGetAllWholeseller(responseJson.data.results);
    // };
    const getAgentAllLists = async () => {
        const responseJson = await AppService.getAllAgentList();
        setAllagents(responseJson.data.results)
    }
    // const getAllListss = async (page: any) => {
    //   const responseJson = await AppService.getAllWholesellerListM({ page: page ? page : 1 });
    //   SetTotalCount(responseJson.data.count)
    //   if (responseJson.data.results) {
    //     setloading(false)
    //   }
    //   setGetAllWholesellers(responseJson.data.results);
    //   setfilterAllwholeseller(responseJson.data.results)
    //   setfilterAllwholesellerM(responseJson.data.results)
    // };

    // const handlePageChange = (event: any, value: any) => {
    //   console.log("first", value)
    //   setCurrentPage(value);
    //   getAllListss(value);
    // };

    const getWholesalerBranchAPI = async () => {
        const res = await AppService.getWholesalerBranch()
        setWholesalerbranch(res.data.results)
    }

    const handleDeleteFirm = async (index: number) => {
        if (window.confirm('Do You want to delete Wholeseller')) {
            let deleteuserid = getAllWholesellers[index].id;
            const responseJson = await AppService.deleteWholeseller(deleteuserid);
            if (responseJson.status == 204) {
                Alert('Wholeseller Delete Successfully');
                // getAllLists(currentPage);
                getWholesellers()
            }
        }
    }

    async function convertImageTobS4(imgUrl: string) {
        const imageToBase64 = require('image-to-base64/browser.js');
        let response = await imageToBase64(imgUrl);
        return "data:image/png;base64," + response;
    }

    const handleKycApproveAgent = async (index: number, wholeseller_status: string) => {
        let textmsg = ""
        if (wholeseller_status == 'KYCAPPROVED') {
            textmsg = "Do You want to approve wholeseller KYC ?";
        }
        if (wholeseller_status == 'KYCREJECTED') {
            textmsg = "Do You want to reject wholeseller KYC ?";
        }
        if (window.confirm(textmsg)) {
            let wholeseller = getAllWholesellers[index];
            wholeseller.wholeseller_status = wholeseller_status;
            if (wholeseller.wholeseller_pan_card_image.includes("https")) {
                wholeseller.wholeseller_pan_card_image = await convertImageTobS4(wholeseller.wholeseller_pan_card_image);
            }
            if (wholeseller.wholeseller_adhar_front_image.includes("https")) {
                wholeseller.wholeseller_adhar_front_image = await convertImageTobS4(wholeseller.wholeseller_adhar_front_image);
            }
            if (wholeseller.wholeseller_adhar_back_image.includes("https")) {
                wholeseller.wholeseller_adhar_back_image = await convertImageTobS4(wholeseller.wholeseller_adhar_back_image);
            }
            if (wholeseller.wholeseller_image.includes("https")) {
                wholeseller.wholeseller_image = await convertImageTobS4(wholeseller.wholeseller_image);
            }
            const responseJson = await AppService.kycApproveWholeseller(wholeseller.id, wholeseller);
            if (responseJson.status == 200) {
                setisshowmore(false);
                if (wholeseller_status == 'KYCAPPROVED') {
                    Alert('Wholeseller KYC APPROVED Successfully');
                }
                if (wholeseller_status == 'KYCREJECTED') {
                    Alert('Wholeseller KYC REJECTED Successfully');
                }
                // getAllListss(currentPage);
                getWholesellers();
            }
        }
    }

    const handleWholeSellerStatus = async (e: any, index: number, id: any, item: any) => {
        let textmsg = ""
        console.log(item, 'itemitem')
        if (e.target.checked) {
            console.log()
            textmsg = "Do you want to active wholeseller ?";
        } else {
            textmsg = "Do you want to inactive wholeseller ?";
        }
        // alert(index);

        if (window.confirm(textmsg)) {
            // let wSellertData = getAllWholesellers[index];
            let wSellertData: any = {};
            console.log(wSellertData, "wSellertData");
            wSellertData.wholeseller_active = e.target.checked;
            // if (wSellertData.wholeseller_pan_card_image?.includes("https")) {
            //   wSellertData.wholeseller_pan_card_image = await convertImageTobS4(wSellertData.wholeseller_pan_card_image);
            // }
            // else {
            //   wSellertData.wholeseller_pan_card_image = undefined
            // }
            // if (wSellertData.wholeseller_adhar_front_image?.includes("https")) {
            //   wSellertData.wholeseller_adhar_front_image = await convertImageTobS4(wSellertData.wholeseller_adhar_front_image);
            // }
            // else {
            //   wSellertData.wholeseller_adhar_front_image = undefined
            // }
            // if (wSellertData.wholeseller_adhar_back_image?.includes("https")) {
            //   wSellertData.wholeseller_adhar_back_image = await convertImageTobS4(wSellertData.wholeseller_adhar_back_image);
            // }
            // else {
            //   wSellertData.wholeseller_adhar_back_image = undefined
            // }
            // if (wSellertData.wholeseller_image?.includes("https")) {
            //   wSellertData.wholeseller_image = await convertImageTobS4(wSellertData.wholeseller_image);
            // }
            // else {
            //   wSellertData.wholeseller_image = undefined
            // }
            const responseJson = await AppService.updateWholeseller(id, wSellertData);
            if (responseJson.status == 200) {
                if (e.target.checked) {
                    Alert('Wholeseller Inactive Successfully');
                }
                else {
                    Alert('Wholeseller Active Successfully');
                }
                // getAllListss(currentPage);
                getWholesellers();
            }
        }
    }

    useEffect(() => {
        // getAllLists();
        // getAllListss(1);
        // getAllAgentTypes()
        // // getAllAgentTypeData()
        // getAllWholesellerTypeAPI()
        // // getAllWholesellerTypeData()
        // getAllActiveInactive()
        // // getAllActiveInactiveData()
        // getAllStatus()
        // getAllStatusData()
        // handlePageChange("e", currentPage)
    }, []);
    useEffect(() => {
        // filterAllwholeseller?.map((item: any) => {
        //   getAllDis({ ids: item?.district }, "")
        //   getAllState({ ids: item?.state }, "")
        //   getAllCity({ ids: item?.city }, "")
        // });
        getAllDis({}, "")
        getAllState({}, "")
        getAllCity({}, "")
        getAllStateData()
        getAllWholesellerTypeAPI();
        // getAllDisData()
        // getAllCityData()
        // getAllAgentTypes()
        // // getAllAgentTypeData()
        // getAllWholesellerTypeAPI()
        // // getAllWholesellerTypeData()
        // getAllActiveInactive()
        // // getAllActiveInactiveData()
        // getAllStatus()
        // getAllStatusData()
    }, [])

    const getAllDis = async (param: any, type: any) => {
        const responseJson = await AppService.getTotalDistrict(param)
        setAllDis(responseJson.data);
    };
    const getAllState = async (param: any, type: any) => {
        const responseJson = await AppService.getTotalCity(param)
        setAllState(responseJson.data);
    };
    const getAllCity = async (param: any, type: any) => {
        const responseJson = await AppService.getAllCity(param)
        setAllCity(responseJson.data.results);
    };
    const getAllWholesellerTypeAPI = async () => {
        const responseJson = await AppService.getWholesellerType({});
        getAllWholesellerTypeData(responseJson.data.results);
    };
    const getAllWholesellerTypeData = async (uniqueArray: any) => {
        let tempState = uniqueArray?.map((row: any) => {
            return {
                label: row.wholeseller_type_name,
                value: row.id,
            }
        })
        setWholesellerlist(tempState);
    };

    useEffect(() => {
        const ids = AllState?.filter((item: any) => item.status === true)?.map((item: any) => item.id);
        if (ids.length) {
            getAllDisData({ ids: ids.join(",") })
        }
    }, [AllState])

    useEffect(() => {
        const ids = allDis?.filter((item: any) => item.status === true)?.map((item: any) => item.id);
        if (ids.length) {
            getAllCityData({ ids: ids.join(",") })
        }
    }, [allDis])


    const getAllStateData = async () => {
        const responseJson = await AppService.getTotalCity();
        let tempState = await responseJson.data.map((row: any) => {
            return {
                label: row.state,
                value: row.id,
            }
        })
        setstateList(tempState);
    };
    const getAllDisData = async (params: any) => {
        const responseJson = await AppService.getAllDistricByState(params);
        let tempState = await responseJson.data.results.flatMap((row: any) => {
            return row.district.map((item: any) => {
                return {
                    label: item.district,
                    value: item.id,
                }
            })
        })
        console.log("districts......", tempState)
        setdisList(tempState);
    };
    const getAllCityData = async (params: any) => {
        const responseJson = await AppService.getAllCityByDis(params);
        let tempState = await responseJson.data.results.flatMap((row: any) => {
            return row.city.map((item: any) => {
                return {
                    label: item.city,
                    value: item.id,
                }
            })
        })
        setcityList(tempState);
    };
    // const getAllAgentTypes = async () => {
    //   const responseJson = await AppService.getAllAgentList();
    //   const arr = responseJson.data.results.map((item: any) => item.agent_type);
    //   var uniqueArray = Array.from(new Set(arr));
    //   getAllAgentTypeData(uniqueArray);
    // }
    // const getAllAgentTypeData = async (uniqueArray:any) => {
    //   if (uniqueArray.length > 0) {
    //     let tempState = uniqueArray?.map((row: any) => {
    //       return {
    //         label: row,
    //         value: row,
    //       }
    //     })
    //     setagentTypeList(tempState);
    //   }
    // };
    // const getAllActiveInactive = async () => {
    //   const responseJson = await AppService.getAllListwholesellerNew();
    //   const arr = responseJson.data.results?.map((item: any) => {
    //     if (item?.wholeseller_active === true) {
    //       return true
    //     } else {
    //       return false
    //     }
    //   });
    //   var uniqueArray = Array.from(new Set(arr));
    //   getAllActiveInactiveData(uniqueArray);
    // }
    // const getAllActiveInactiveData = async (uniqueArray:any) => {
    //   if (uniqueArray.length > 0) {
    //     let tempState = uniqueArray.map((row: any) => {
    //       return {
    //         label: row === true ? "Enable" : "Disable",
    //         value: row === true ? true : false,
    //       }
    //     })
    //     setactiveInactiveList(tempState);
    //   }
    // };
    // const getAllStatus = async () => {
    //   const responseJson = await AppService.getAllListwholesellerNew();
    //   const arr = responseJson.data.results?.map((item: any) => item?.wholeseller_status);
    //   var uniqueArray = Array.from(new Set(arr));
    //   setAllstatus(uniqueArray);
    // }
    // const getAllStatusData = async () => {
    //   if (Allstatus?.length > 0) {
    //     let tempState = Allstatus?.map((row: any) => {
    //       return {
    //         label: row,
    //         value: row,
    //       }
    //     })
    //     setstatusList(tempState);
    //   }
    // };

    const handleChange = (selectboxName: string, id: any) => {
        if (selectboxName === 'State') {
            setAllState((prev: any) => {
                return prev.map((item: any) => item.id === id ? { ...item, status: !item.status } : item)
            });
            console.log(id, "idid", selectboxName, AllState)
        }
        if (selectboxName === 'District') {
            setAllDis((prev: any) => {
                return prev.map((item: any) => item.id === id ? { ...item, status: !item.status } : item)
            });
        }
        if (selectboxName === 'City') {
            setAllCity((prev: any) => {
                return prev.map((item: any) => item.id === id ? { ...item, status: !item.status } : item)
            });
        }
        if (selectboxName === 'Wholesaler Type') {
            setWholesellerlist((prev: any) => {
                return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            });
        }
        if (selectboxName === 'Agent Type') {
            setagentTypeList((prev: any) => {
                return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            });
        }
        if (selectboxName === 'Status') {
            setstatusList((prev: any) => {
                return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            });
        }
        if (selectboxName === 'Active/Inactive') {
            setactiveInactiveList((prev: any) => {
                return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            });
        }
    }

    const [isshowmore, setisshowmore] = useState(false);


    console.log("Wholesellerlist", AllCity)

    return (
        <>
            {
                loading ? <Loading /> :
                    <div className={classes.root}>
                        {/* <SectionHeader /> */}
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {/* <div className="headTitle">Wholesaler</div> */}
                            </Grid>
                            <Grid item xs={6}>
                                <div className="actionArea">
                                    <div className="flex gap-2 search-div">
                                        <div className="relative" style={{ width: "500px", marginRight: "10px" }}>
                                            <input
                                                type="text"
                                                value={searchK}
                                                onChange={(e) => setSearchK(e.target.value)}
                                                placeholder="Search by City , Name, Mobile Number, Type etc"
                                                className="w-full py-[11px] pl-12 pr-4 text-gray-500 border rounded-md outline-none bg-gray-50 focus:bg-white"
                                                style={{ fontSize: "14px", fontWeight: 500, fontFamily: "Manrope", color: "#84818A", paddingLeft: "16px" }}
                                            // onKeyDown={(e:any)=>e.key === 'Enter' && getWholesellers()}
                                            />
                                            {
                                                searchK ?
                                                    <div onClick={() => setSearchK("")}>
                                                        <Close
                                                            className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400"
                                                            style={{ right: "16px", color: "#504F54", width: "17.49px", height: "17.49px" }}
                                                        />
                                                    </div>
                                                    :
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        style={{ right: "16px", color: "#504F54", width: "17.49px", height: "17.49px" }}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                        />
                                                    </svg>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <div className="flex gap-5 pt-[30px] " style={{ flexWrap: "wrap", paddingBottom: "30px" }}>
                            <CommonSelectElectronicBazaar
                                label={"State"}
                                hint={"Select State"}
                                options={stateList}
                                handleSelect={handleChange}
                                selectedIds={AllState?.filter((item: any) => item?.status).map((elm: any) => elm.id)}
                            />
                            <CommonSelectElectronicBazaar
                                label={"District"}
                                hint={"Select District"}
                                options={disList}
                                handleSelect={handleChange}
                                selectedIds={allDis.filter((item: any) => item?.status).map((elm: any) => elm.id)}
                            />
                            <CommonSelectElectronicBazaar
                                label={"City"}
                                hint={"Select City"}
                                options={cityList}
                                handleSelect={handleChange}
                                selectedIds={AllCity.filter((item: any) => item?.status).map((elm: any) => elm.id)}
                            />
                            <CommonSelectElectronicBazaar
                                label={"Wholesaler Type"}
                                hint={"Select Wholesaler Type"}
                                options={Wholesellerlist}
                                handleSelect={handleChange}
                                selectedIds={Wholesellerlist?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                            />
                            <CommonSelectElectronicBazaar
                                label={"Status"}
                                hint={"Select Status"}
                                options={statusList}
                                handleSelect={handleChange}
                                selectedIds={statusList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                            />
                            <CommonSelectElectronicBazaar
                                label={"Enable/Disable"}
                                hint={"Select Enable/Disable"}
                                options={activeInactiveList}
                                handleSelect={handleChange}
                                selectedIds={activeInactiveList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                            />
                            {/* <CommonSelectElectronicBazaar
                  label={"Agent Type"}
                  hint={"Select Agent Type"}
                  options={agentTypeList}
                  handleSelect={handleChange}
                  selectedIds={agentTypeList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                /> */}
                            {/* <CommonSelectElectronicBazaar
              label={"Plan Status"}
              hint={"Select Plan Status"}
              options={[]}
              handleSelect={handleChange}
              selectedIds={[]}
            /> */}
                        </div>
                        <div className="main-wholeseller-table">
                            <div style={{ overflowX: "auto" }}>
                                <table>
                                    <tr>
                                        <th>Name</th>
                                        <th>Contact Person</th>
                                        <th>City</th>
                                        <th>Bazaar</th>
                                        <th>Type</th>
                                        {/* <th>Agent</th> */}
                                        <th>Status</th>
                                        <th>Plan Expiry</th>
                                        {/* <th>Enable/Disable</th> */}
                                    </tr>

                                    {
                                        getAllWholesellers.length ?
                                    getAllWholesellers.map((item: any, index: any) => (
                                        <tr key={index}>
                                            <td >{item.wholeseller_firm_name}</td>
                                            <td className="person-details" onClick={() => navigate(`/wholesalerdetails/${item.id}`)}>
                                                <div className="flex items-center gap-2 text-[#4E2FA9]">
                                                    <img className="brandLogo" src={contact} alt={"Logo"} />
                                                    <span style={{ textDecoration: "underline" }}>{item.wholeseller_contact_per}</span>
                                                </div>
                                                <div className="flex flex-col items-start gap-2 person-model">
                                                    <span className="job-title">Firm Manager</span>
                                                    <span className="text-sm text-[#2E2C34] font-medium">
                                                        {item.wholeseller_contact_per}
                                                    </span>
                                                    <span className="text-[13px] text-[#84818A] font-medium leading-5">
                                                        {item.wholeseller_city_name}, {item.wholeseller_state_name}
                                                    </span>
                                                    <span className="flex items-center gap-2 text-sm text-[#2E2C34] font-medium">
                                                        <img src={phone} alt="phone" /> {item.wholeseller_number}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>{item.wholeseller_city_name}</td>
                                            <td className="overlap"> {item.wholeseller_bazaar_data.map((row: any) => row.bazaar_name)}</td>
                                            <td className="person-type">{item.wholeseller_type_name}</td>
                                            {/* <td className="person-details">
                          <div className="flex items-center gap-2 text-[#4E2FA9]">
                            <img className="brandLogo" src={contact} alt={"Logo"} />
                            <span className="font-[500] text-[#4E2FA9] text-[14px] font-[Manrope]" style={{ textDecoration: "underline" }}>{item?.wholeseller_agent?.agent_name}</span>

                          </div>
                          {
                            item?.wholeseller_agent &&
                            <div className="flex flex-col items-start gap-2 person-model">
                              <span className="job-title">{item?.wholeseller_agent?.agent_type}</span>
                              <span className="text-sm text-[#2E2C34] font-medium">
                                <span className="font-[500] text-[#2E2C34] text-[14px] font-[Manrope]">{item?.wholeseller_agent?.agent_name}</span>

                              </span>
                              <span className="text-[13px] text-[#84818A] font-medium leading-5">
                                {item?.wholeseller_agent?.agent_city_name}, {item?.wholeseller_agent?.agent_state_name}
                              </span>
                              <span className="flex items-center gap-2 text-sm text-[#2E2C34] font-medium">
                                <img src={phone} alt="phone" /> {item?.wholeseller_agent?.agent_number}
                              </span>
                            </div>
                          }
                        </td> */}
                                            <td className="py-4 px-6 tableData" style={{ paddingLeft: 0, fontSize: "12px" }}>
                                                {item.wholeseller_status == 'CREATED' && <div className="bg-[#e5f5ff] flex justify-center rounded-md p-[10px] w-[150px]">
                                                    <p className="text-[#28a1ff]">{item.wholeseller_status}</p>
                                                </div>}
                                                {item.wholeseller_status == 'PENDING' && <div className="bg-[#FFF6ED] flex justify-center rounded-md p-[10px] w-[150px]" onClick={() => navigate(`/wholesellerkyc/${item.id}/EDIT`)} >
                                                    <p className="text-[#FFA043]">{item.wholeseller_status}</p>
                                                </div>}
                                                {item.wholeseller_status == 'KYCAPPROVED' && <div className="bg-[#e6fcf7] flex justify-center rounded-md p-[10px] w-[150px]" onClick={() => navigate(`/wholesellerkyc/${item.id}/EDIT`)} >
                                                    <p className="text-[#00e0c0]">{item.wholeseller_status}</p>
                                                </div>}
                                                {item.wholeseller_status == 'KYCREJECTED' && <div className="bg-[#ffeae5] flex justify-center rounded-md p-[10px] w-[150px]" onClick={() => navigate(`/wholesellerkyc/${item.id}/EDIT`)}>
                                                    <p className="text-[#ff0000]">{item.wholeseller_status}</p>
                                                </div>}
                                                {item.wholeseller_status == 'APPROVED' && <div className="bg-[#FFF6ED] flex justify-center rounded-md p-[10px] w-[150px]" onClick={() => navigate(`/wholesellerkyc/${item.id}/EDIT`)}>
                                                    <p className="text-[#FFA043]">{item.wholeseller_status}</p>
                                                </div>}
                                            </td>
                                            <td>
                                                <div className="text-[#FFA043] text-sm font-medium">
                                                    {moment(item.kyc_approve_date).add(item?.wholeseller_plan_data?.plan_periods_in_days, "days").format("DD-MM-YYYY")}
                                                    {/* {item?.wholeseller_plan?.[0]?.end_date}
                            {" "}
                            {item?.wholeseller_plan?.[0]?.end_time} */}

                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <Switch checked={item.wholeseller_active} onChange={(e: any) => handleWholeSellerStatus(e, index, item.id, item)} />
                                                </div>
                                            </td>

                                        </tr>
                                    )) :
                                    <p className="py-5 text-lg">No wholesellers fount!</p>
                                    }
                                </table>
                                <div
                                    className="flex items-center justify-between"
                                    style={{ display: "flex", marginLeft: 0, justifyContent: "center", marginTop: "40px", marginBottom: "150px" }}
                                >
                                    <Pagination
                                        count={Math.ceil(totalCount / 10)}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
            }
        </>
    );
};

export default AgentWholesaler;
