import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextType {
  isAuthenticated: boolean;
  checkLogin: () => void;
}


const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};


export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const checkLogin = () => {
    const userData = localStorage.getItem('user');
  const token = userData ? JSON.parse(userData).access_token : null;
    setIsAuthenticated(!!token); 
  };


  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, checkLogin }}>
      {children}
    </AuthContext.Provider>
  );
};
