import React, { useEffect, useRef, useState } from "react";
import { useAuthenticationLayoutStyles } from "@/static/stylesheets/layouts";
import backIcon from "@/static/icons/ic_back_icon.png";

interface OtpFormProps {
  onSuccess(code:string): any;
  onGoBack?(): void;
}

interface InputRefs {
  [key: number]: React.RefObject<HTMLInputElement>;
}

const OtpForm: React.FC<OtpFormProps> = (props) => {
  const classes = useAuthenticationLayoutStyles();
  const [inputs, setInputs] = useState(['', '', '', '', '', '']);
  const inputRefs: InputRefs = {
    0: useRef<HTMLInputElement>(null),
    1: useRef<HTMLInputElement>(null),
    2: useRef<HTMLInputElement>(null),
    3: useRef<HTMLInputElement>(null),
    4: useRef<HTMLInputElement>(null),
    5: useRef<HTMLInputElement>(null),
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;

    // Update the inputs array with the new value
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);

    // Check if the value length is 0 and move focus to the previous input
    if (value.length === 0 && index > 0) {
      const prevIndex = index - 1;
      inputRefs[prevIndex]?.current?.focus();
    }

    // Check if the value length is 1 and move focus to the next input
    if (value.length === 1 && index < Object.keys(inputRefs).length - 1) {
      const nextIndex = index + 1;
      inputRefs[nextIndex]?.current?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    // Move focus to the previous input on backspace when the input is empty
    if (e.key === 'Backspace' && inputs[index] === '' && index > 0) {
      const prevIndex = index - 1;
      inputRefs[prevIndex]?.current?.focus();
    }
  };

  // This useEffect ensures that the first input is focused when the component mounts
  useEffect(() => {
    inputRefs[0]?.current?.focus();
  }, []);


  return (
    <>
      <div className={classes.otpTitle}>
        <span className={"back-button"} onClick={props?.onGoBack}>
          <img src={backIcon} alt={"Go Back"} />
        </span>
        OTP Verification
      </div>
      <div className={classes.hint}>
        Enter the code we sent to the mobile number ending in 6441
      </div>

      <div className={classes.otpInputContainer}>
      {Array.from({ length: 6 }, (_, index) => (
        <input
          key={index}
          type="text"
          value={inputs[index]}
          onChange={(e) => handleInputChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          maxLength={1}
          ref={inputRefs[index]}
        />
      ))}
      </div>

      <div className={classes.submitButtonContainer}>
        <button className={classes.submitButton} onClick={()=>{props?.onSuccess(inputs.join(""))}}>
          Verify
        </button>
      </div>
    </>
  );
};

export { OtpForm };
