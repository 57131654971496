
import AgentReport from "./AgentReport";
export default AgentReport;


export function formatIndianRupee(value:any) {

    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    });
  
    return formatter.format(Number(value));
  }